import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	getDocumentDetails,
	unmountDocumentProperties,
	updateDocumentProperties,
	setFavorite,
	uploadNewVersion,
	deleteVersion,
	deleteDocuments,
	restoreDocuments,
	setLockStatus,
	getSignedUrlForDocumentAndDownload,
	updateVersionComment,
	openDocumentInLauncher,
	convertDocumentToPDF,
} from "../actions/documentActions";
import { requestOCR } from "../actions/ocrJobProgressActions";

import { openGlobalDialog } from "../actions/globalEditDialogActions";
import { resetTableColumnWidths } from "../actions/tableSortingActions";
import { ButtonWithIcon } from "./ButtonWithIcon";
import { DropDownButtonWithRouter } from "./DropDownButton";
import SortableTable from "./Tables/SortableTable";
import InteractiveTableCell from "./Tables/InteractiveTableCell";
import { BreadcrumbsWithMatterName } from "./Breadcrumbs";

import CustomPagination from "./Tables/CustomPagination";

import { LabeledTextInput } from "./TextInput";
import { LabeledTextArea } from "./TextArea";
import { LabeledDropDownSelect } from "./DropDownSelect";
import FavoriteStar from "./FavoriteStar";

import TwoColumnWrapper from "./TwoColumnWrapper";
import {
	mediumDateFormat,
	longDateFormat,
	getCheckoutMenuItems,
	splitFilename,
	mapFirmDocsMatterID,
	convertToPDFAllowed,
	ocrOnDemandAllowed,
} from "../utilities/utilities";

const tableUniqueID = "/documentProperties";

const tagishMap = (id, available) => {
	return id
		? (() => {
				const matchingItem = available.filter((item) => item.id === id);

				if (matchingItem && matchingItem.length) {
					const val = matchingItem[0].name;
					return {
						label: val,
						value: val,
						id: id,
					};
				} else {
					return null;
				}
			})()
		: null;
};
const tagMap = (item) => ({ label: item.name, value: item.name, id: item.id });

const getLockText = (lockStatus, name) => {
	if (lockStatus === 0) {
		return `Currently locked by ${name || "???"}`;
	} else if (lockStatus === 1) {
		return `Currently in use by ${name || "???"}`;
	} else if (lockStatus === 2) {
		return `Currently checked out to ${name || "???"}`;
	} else if (lockStatus === 3) {
		return `Currently checked out to & in use by ${name || "???"}`;
	}
	return "";
};

var debounceTimeout = null;
class DocumentProperties extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: true,
			documentName: "",
			documentType: {},
			documentStatus: {},
			documentTags: [],
			documentNotes: "",
			characterCount: "",
			isFavorite: false,
			ableToModify: true,
			documentNotInUse: true,
			basename: "",
			extension: "",
		};
		this.columnConfig = [
			{
				Header: "Ver",
				accessor: "versionNumber",
				Cell: (row) => (
					/*

					this.props.openDocumentInLauncher(
											this.props.documentDetails
												.documentID
										)

					*/
					<InteractiveTableCell
						menuItems={[
							row.index === 0 && this.state.ableToModify
								? {
										text: "Open",
										onSelect: () => {
											this.props.openDocumentInLauncher(
												this.props.documentDetails
													.documentID,
												this.props.cognitoSub
											);
										},
									}
								: {
										text: "Download a Copy",
										onSelect: () => {
											this.props.getSignedUrlForDocumentAndDownload(
												this.props.documentDetails
													.documentID,
												row.original.versionID
											);
										},
									},
						]
							.concat(
								row.index === 0
									? [
											{
												text:
													(row.original.comment
														? "Edit"
														: "Add") +
													" Version Comment",
												disabled:
													!this.state.ableToModify,
												onSelect:
													this
														.addVersionCommentHandler,
											},
										]
									: row.original.comment
										? [
												{
													text: "View Version Comment",
													onSelect: () => {
														this.viewVersionComment(
															row.original
																.versionNumber
														);
													},
												},
											]
										: []
							)
							.concat(
								this.props.documentList.length < 2
									? []
									: [
											{
												text: "Delete Version",
												onSelect: () =>
													this.props.deleteVersion(
														this.props
															.documentDetails
															.prefix,
														this.props.match.params
															.fullpath,
														row.original.versionID,
														row.original
															.versionNumber
													),
											},
										]
							)}
					>
						<span>v{row.value}</span>
					</InteractiveTableCell>
				),
				width: 80,
			},
			{
				Header: "Created",
				accessor: "createdAt",
				Cell: (row) => mediumDateFormat(row.value),
				width: 150,
			},
			{ Header: "By", accessor: "createdBy", width: 100 },

			{ Header: "Comment", accessor: "comment" },
		];
		this.handleChange = this.handleChange.bind(this);
		this.toggleFavorite = this.toggleFavorite.bind(this);
		this.fileListChangeHandler = this.fileListChangeHandler.bind(this);
		this.uploadNewVersion = this.uploadNewVersion.bind(this);
		this.addVersionCommentHandler =
			this.addVersionCommentHandler.bind(this);
		this.viewVersionComment = this.viewVersionComment.bind(this);
	}
	static getDerivedStateFromProps(props, state) {
		if (state.loading && !props.loading) {
			const { basename, extension } = splitFilename(
				props.documentDetails.filename
			);
			const newState = {
				loading: false,
				documentName: props.documentDetails.filename,
				documentType: tagishMap(
					props.documentDetails.documentTypeID,
					props.availableDocumentTypes
				),
				documentStatus: tagishMap(
					props.documentDetails.documentStatusID,
					props.availableDocumentStatuses
				),
				documentTags: props.documentDetails.tags
					? props.documentDetails.tags.map(tagMap)
					: [],
				documentNotes: props.documentDetails.notes
					? props.documentDetails.notes
					: "",
				characterCount: props.documentDetails.notes
					? props.documentDetails.notes.length + "/1000"
					: "",
				isFavorite: props.documentDetails.isFavorite,
				ableToModify:
					!props.documentDetails.lockedBySub ||
					props.documentDetails.lockedBySub === props.cognitoSub,
				documentNotInUse:
					props.documentDetails.lockStatus === 0 ||
					props.documentDetails.lockStatus === 2,
				basename: basename,
				extension: extension,
			};
			return newState;
		} else if (props.loading && !state.loading) {
			return { loading: true };
		} else {
			return null;
		}
	}
	getUrlPrefix() {
		return (
			"/matters/" +
			this.props.match.params.id +
			"/" +
			this.props.match.params.root +
			"/"
		);
	}
	componentWillUnmount() {
		this.props.unmountDocumentProperties();
	}
	handleChange(key, value) {
		var newState = { [key]: value };
		if (key === "documentNotes") {
			newState.characterCount = value.length + "/" + 1000;
			if (value.length > 1000) {
				newState.notesTooLong = true;
			} else {
				newState.notesTooLong = false;
			}
		} else if (key === "basename") {
			var totalFilenameLength =
				value.length + this.state.extension.length + 1;
			if (totalFilenameLength > 255) {
				newState.filenameTooLong = true;
			} else {
				newState.filenameTooLong = false;
			}
			//Check for case-insensitive duplicate in current folder
			newState.matchesFilename =
				this.props.documentFileNamesInSameDirectory.filter(
					(item) =>
						item.filename.toUpperCase() ===
						value.toUpperCase() +
							"." +
							this.state.extension.toUpperCase()
				).length;
			newState.matchesDeletedFilename =
				this.props.documentFileNamesInSameDirectory.filter(
					(item) =>
						item.filename.toUpperCase() ===
							value.toUpperCase() +
								"." +
								this.state.extension.toUpperCase() &&
						item.isDeleted
				).length;
			if (newState.matchesDeletedFilename) {
				console.log("deleted match");
			}
		}
		this.setState(newState);
	}
	submit() {
		if (this.state.ableToModify) {
			this.props.updateDocumentProperties(
				this.props.match.params.fullpath,
				this.state
			);
		} else {
			window.globalHistory.goBack();
		}
	}
	uploadNewVersion(files, callback) {
		if (this.state.documentNotInUse && this.state.ableToModify) {
			const documentFullpath =
				this.props.documentDetails.path +
				this.props.documentDetails.name +
				"/";
			const mappedMatterID = mapFirmDocsMatterID(
				this.props.documentDetails.matterID || 0,
				documentFullpath
			);
			this.props.uploadNewVersion(
				this.props.documentDetails.documentID,
				files,
				mappedMatterID,
				callback
			);
		}
	}
	toggleFavorite() {
		clearTimeout(debounceTimeout);
		debounceTimeout = setTimeout(() => {
			this.props.setFavorite(
				this.props.documentDetails.documentID,
				this.state.isFavorite
			);
		}, 1000);
		this.handleChange("isFavorite", !this.state.isFavorite);
	}
	componentDidMount() {
		this.props.getDocumentDetails(this.props.match.params.fullpath);
	}
	componentDidUpdate(prevProps) {
		if (
			prevProps.match.params.fullpath !== this.props.match.params.fullpath
		) {
			this.setState({ loading: true }, () => {
				this.props.getDocumentDetails(this.props.match.params.fullpath);
			});
		}
	}
	fileListChangeHandler(files, callback) {
		const oldName = this.state.documentName;
		const newName = files[0].name;
		if (newName !== oldName) {
			this.props.openGlobalDialog(
				{
					label: "Upload New Version with new name?",
					mainText: `The previous version of this document was named "${oldName}".  You selected a file version named "${newName}".  Are you sure you want to upload "${newName}" as a new version?`,
					submitButtonLabel: "Yes - Upload",
					canSubmit: true,
					hideTextfield: true,
				},
				() => this.uploadNewVersion(files, callback),
				() => {
					return {
						canSubmit: true,
					};
				}
			);
		} else {
			this.uploadNewVersion(files, callback);
		}
	}
	viewVersionComment(versionNumber) {
		this.props.openGlobalDialog(
			{
				label: `Comment for v${versionNumber}`,
				value: this.props.documentList.filter(
					(v) => v.versionNumber === versionNumber
				)[0].comment,
				readOnly: true,
				canSubmit: true,
				cancelButtonLabel: "Back",
			},
			() => {},
			() => {
				return {
					canSubmit: true,
					erroMessage: "",
				};
			}
		);
	}
	addVersionCommentHandler() {
		this.props.openGlobalDialog(
			{
				label: `Comment for Current Version (v${this.props.documentList[0].versionNumber})`,
				value: this.props.documentList[0].comment,
				canSubmit: true,
				submitButtonLabel: "Submit",
			},
			(state) => {
				this.props.updateVersionComment(
					this.props.documentDetails.documentID,
					this.props.documentList[0].versionNumber,
					this.props.documentList[0].versionID,
					state.value
				);
			},
			() => {
				return {
					canSubmit: true,
					erroMessage: "",
				};
			}
		);
	}
	render() {
		if (
			this.state.loading ||
			this.props.loading ||
			!this.props.documentDetails ||
			!this.props.documentList
		) {
			return (
				<div>
					<h1>
						Loading<span className="Loading">...</span>
					</h1>
				</div>
			);
		} else {
			return (
				<TwoColumnWrapper
					headingText="Properties"
					iconName="file"
					canSubmit={
						this.state.ableToModify &&
						this.state.documentName !== "" &&
						!this.state.filenameTooLong &&
						!this.state.notesTooLong &&
						!this.state.matchesFilename
					}
					submit={this.submit.bind(this)}
					submitButtonText="Update"
					cancelButtonText={
						this.state.ableToModify ? "Cancel" : "Back"
					}
					rightHeading={
						<div
							style={{
								marginLeft: "auto",
								marginTop: 0,
								marginBottom: 20,
								display: "flex",
							}}
						>
							<div style={{ marginRight: 8 }}>
								<ButtonWithIcon
									handler={() =>
										this.props.openDocumentInLauncher(
											this.props.documentDetails
												.documentID,
											this.props.cognitoSub
										)
									}
									disabled={
										!(
											this.props.launcherInstalled &&
											this.state.ableToModify
										)
									}
									buttonText="Open Document"
									icon={"external-link-alt"}
								/>
							</div>
							<div style={{ marginRight: 8 }}>
								<ButtonWithIcon
									handler={this.toggleFavorite}
									buttonText="Favorite"
									iconComponent={
										<FavoriteStar
											isFavorite={this.state.isFavorite}
											style={{
												float: "left",
												top: 3,
												fontSize: "1.2em",
											}}
										/>
									}
								/>
							</div>
							<div style={{ marginRight: 8 }}>
								<ButtonWithIcon
									handler={this.uploadFileHandler}
									fileListChangeHandler={
										this.fileListChangeHandler
									}
									disabled={
										this.state.documentNotInUse &&
										this.state.ableToModify
											? false
											: true
									}
									disabledHoverText={
										this.state.ableToModify &&
										!this.state.documentNotInUse
											? "You have this document open in the launcher"
											: "This document is locked by another user"
									}
									buttonText="Upload New Version"
									iconName="file-upload"
									type="single-upload"
								/>
							</div>
							{convertToPDFAllowed(
								this.props.documentDetails.filename
							) ? (
								<div style={{ marginRight: 8 }}>
									<ButtonWithIcon
										handler={() =>
											this.props.convertDocumentToPDF({
												sourceDocumentID:
													this.props.documentDetails
														.documentID,
												fileToConvert:
													this.props.documentDetails
														.filename,
												matterID:
													this.props.documentDetails
														.matterID,
												actionType: "createPDF",
											})
										}
										buttonText="Convert to PDF"
										iconName="file-pdf"
									/>
								</div>
							) : (
								""
							)}
							{ocrOnDemandAllowed(
								this.props.documentDetails.filename
							) ? (
								<div style={{ marginRight: 8 }}>
									<ButtonWithIcon
										disabled={
											!this.props.isOcrEnabled ||
											this.props.isTenantOverOcrLimit ||
											(this.state.documentNotInUse &&
											this.state.ableToModify
												? false
												: true)
										}
										disabledHoverText={
											!this.props.isOcrEnabled
												? "Your firm does not have OCR enabled. Please contact the Uptime Help Desk for more information."
												: this.props
															.isTenantOverOcrLimit
													? "You have reached your monthly OCR Limit. Please contact your Firm Administrator for more information."
													: this.state.ableToModify &&
														  !this.state
																.documentNotInUse
														? "You have this document open in the launcher"
														: "This document is locked by another user"
										}
										handler={() =>
											this.props.requestOCR(
												this.props.documentDetails
													.documentID,
												this.props.documentDetails
													.filename
											)
										}
										buttonText="Request OCR"
										iconName="file-pdf"
									/>
								</div>
							) : (
								""
							)}
							<div>
								<DropDownButtonWithRouter
									title="Actions"
									pullRight
									menuItems={getCheckoutMenuItems(
										this.props.documentDetails.lockedBy,
										this.props.documentDetails.lockedBySub,
										this.props.documentDetails.lockStatus,
										this.props.documentDetails.documentID,
										null,
										this.props.cognitoSub,
										this.props.setLockStatus,
										null,
										this.props.canOverrideDocumentLocks
									).concat([
										{
											text: this.props.documentList.length
												? (this.props.documentList[0]
														.comment
														? "Edit"
														: "Add") +
													" v" +
													(this.props.documentList
														.length &&
														this.props
															.documentList[0]
															.versionNumber) +
													" Version Comment"
												: "No Document Versions",
											disabled:
												!this.props.documentList
													.length ||
												!this.state.ableToModify,
											onSelect:
												this.addVersionCommentHandler,
										},
										{
											text: "Download a Copy",
											onSelect: () => {
												this.props.getSignedUrlForDocumentAndDownload(
													this.props.documentDetails
														.documentID
												);
											},
										},
										{
											text: "Reset Column Widths",
											onSelect: () =>
												this.props.resetTableColumnWidths(
													tableUniqueID
												),
										},
									])}
								/>
							</div>
						</div>
					}
					leftChildren={
						<div>
							<div
								style={{
									marginBottom: 16,
								}}
							>
								<BreadcrumbsWithMatterName
									{...this.props.documentDetails}
								/>
							</div>

							<div>
								<div style={{ display: "flex" }}>
									<div style={{ flexGrow: 1 }}>
										<LabeledTextInput
											label="Document Name*"
											name="basename"
											placeholder="Document Name"
											defaultValue={this.state.basename}
											disabled={!this.state.ableToModify}
											onChange={this.handleChange}
										/>
									</div>
									{this.state.extension !== "" ? (
										<div
											style={{
												display: "relative",
												marginLeft: 8,
												paddingTop: 26,
											}}
										>
											.{this.state.extension}
										</div>
									) : null}
								</div>
								{this.state.filenameTooLong ? (
									<div className="inlineErrorIndicator">
										<div>
											Filename cannot be longer than 255
											characters
										</div>
									</div>
								) : null}
								{this.state.matchesFilename ? (
									<div className="inlineErrorIndicator">
										<div>
											There is already a
											{this.state.matchesDeletedFilename
												? " deleted "
												: " "}
											file in the current directory with
											this name
										</div>
									</div>
								) : null}
							</div>
							<LabeledDropDownSelect
								label="Document Type"
								name="documentType"
								placeholder="Document Type"
								isCreatable
								isClearable
								isAsync
								isDisabled={!this.state.ableToModify}
								options={
									this.props.availableDocumentTypes &&
									this.props.availableDocumentTypes
										.filter(
											(type) => type.status === "active"
										)
										.map((type) => ({
											label: type.name,
											value: type.name,
											id: type.id,
										}))
								}
								defaultValue={tagishMap(
									this.props.documentDetails.documentTypeID,
									this.props.availableDocumentTypes
								)}
								handler={this.handleChange}
							/>
							<LabeledDropDownSelect
								label="Document Status"
								name="documentStatus"
								placeholder="Document Status"
								isCreatable
								isClearable
								isAsync
								isDisabled={!this.state.ableToModify}
								options={
									this.props.availableDocumentStatuses &&
									this.props.availableDocumentStatuses
										.filter(
											(type) => type.status === "active"
										)
										.map((type) => ({
											label: type.name,
											value: type.name,
											id: type.id,
										}))
								}
								defaultValue={tagishMap(
									this.props.documentDetails.documentStatusID,
									this.props.availableDocumentStatuses
								)}
								handler={this.handleChange}
							/>
							<LabeledDropDownSelect
								label="Document Tag(s)"
								isMulti
								name="documentTags"
								isCreatable
								isAsync
								isDisabled={!this.state.ableToModify}
								options={
									this.props.availableDocumentTags &&
									this.props.availableDocumentTags
										.filter(
											(type) => type.status === "active"
										)
										.map((type) => ({
											label: type.name,
											value: type.name,
											id: type.id,
										}))
								}
								defaultValue={
									this.props.documentDetails.tags &&
									this.props.documentDetails.tags.map(tagMap)
								}
								handler={this.handleChange}
							/>
							<div style={{ position: "relative" }}>
								<LabeledTextArea
									label="Internal Notes"
									name="documentNotes"
									onChange={this.handleChange}
									disabled={!this.state.ableToModify}
									defaultValue={
										(this.props.documentDetails &&
											this.props.documentDetails.notes) ||
										""
									}
								/>

								{this.state.notesTooLong ? (
									<div className="inlineErrorIndicator">
										<div>
											Notes cannot be longer than 1000
											characters
										</div>
									</div>
								) : null}
								<div style={{ textAlign: "right" }}>
									{this.state.characterCount}
								</div>
							</div>
							<div>
								<div>
									<span
										className="MainFontBold"
										style={{
											marginRight: 4,
										}}
									>
										Document ID:
									</span>
									<span>
										{this.props.match.params.fullpath}
									</span>
								</div>
								{this.props.documentDetails &&
								(this.props.documentDetails.lockStatus > 0 ||
									this.props.documentDetails.lockedBy) ? (
									<div>
										{getLockText(
											this.props.documentDetails
												.lockStatus,
											this.props.documentDetails.lockedBy
										)}
									</div>
								) : null}
								<div>
									Created on{" "}
									{longDateFormat(
										this.props.documentDetails.createdAt
									) +
										" by " +
										this.props.documentDetails.createdBy}
								</div>

								<div>
									Last Modified on{" "}
									{this.props.documentList &&
										this.props.documentList.length &&
										longDateFormat(
											this.props.documentList[0].createdAt
										) + " "}{" "}
									by{" "}
									{this.props.documentList &&
										this.props.documentList.length &&
										this.props.documentList[0].createdBy}
								</div>
								<div>
									Current Version:{" "}
									{this.props.documentList &&
										this.props.documentList.length &&
										"v" +
											this.props.documentList[0]
												.versionNumber}
								</div>
							</div>
						</div>
					}
					rightChildren={
						<div style={{ position: "relative" }}>
							<div
								className="MainFontBold"
								style={{
									marginBottom: "36px",
								}}
							>
								Document Versions
							</div>
							<SortableTable
								className="fullWidth"
								data={this.props.documentList}
								style={{ height: 458 }}
								columns={this.columnConfig}
								defaultPageSize={10}
								defaultSorted={[
									{
										id: "versionNumber",
										desc: true,
									},
								]}
								uncontrolledPagination
								PaginationComponent={CustomPagination}
								loading={this.props.loading}
								noResultsText="No Document Versions"
								tableUniqueID={tableUniqueID}
							/>

							<div />
						</div>
					}
				/>
			);
		}
	}
}
DocumentProperties.propTypes = {
	loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
	matterDetails: state.matter.matterDetails,
	documentList: state.document.documentVersions,
	documentDetails: state.document.documentDetails,
	cognitoSub: state.user.cognitoUser.attributes.sub,
	availableDocumentTags: state.document.availableDocumentTags,
	availableDocumentTypes: state.document.availableDocumentTypes,
	availableDocumentStatuses: state.document.availableDocumentStatuses,
	documentFileNamesInSameDirectory:
		state.document.documentFileNamesInSameDirectory,
	prefix: state.document.prefix,
	loading: state.document.documentPropertiesLoading,
	launcherInstalled: state.global.launcherInstalled,
	canOverrideDocumentLocks: state.user.canOverrideDocumentLocks,
	isOcrEnabled: !state.tenant.tenantFeaturesLoading
		? state.tenant.tenantFeatures.automaticOCR === 1
		: true,
	isTenantOverOcrLimit: !state.tenant.tenantFeaturesLoading
		? state.tenant.tenantUsage.OCR_TRANSACTIONS_THIS_MONTH >=
			state.tenant.tenantLimits.OCR_PER_MONTH_NET
		: false,
});
export default connect(mapStateToProps, {
	getDocumentDetails,
	unmountDocumentProperties,
	updateDocumentProperties,
	setFavorite,
	uploadNewVersion,
	openGlobalDialog,
	deleteVersion,
	deleteDocuments,
	restoreDocuments,
	setLockStatus,
	getSignedUrlForDocumentAndDownload,
	updateVersionComment,
	openDocumentInLauncher,
	convertDocumentToPDF,
	requestOCR,
	resetTableColumnWidths,
})(DocumentProperties);
