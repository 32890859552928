import {
	EDIT_CLIENT,
	SET_SUBHEADER_TEXT,
	SET_CREATABLE_CONTEXT,
} from "../actions/types";

const initialState = {
	title: "Welcome to LexWorkplace",
	chips: [],
	component: null,
	creatableContext: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_SUBHEADER_TEXT:
			return {
				...state,
				title: action.payload.subheaderText,
				chips: action.payload.subheaderChips || [],
			};
		case EDIT_CLIENT:
			return {
				...state,
				title: action.payload.subheaderText,
			};
		case SET_CREATABLE_CONTEXT:
			return {
				...state,
				creatableContext: action.payload,
			};
		default:
			return state;
	}
}
