import React, { Component } from "react";
import { connect } from "react-redux";

import { DropDownButtonWithRouter } from "./DropDownButton";

import { Link } from "react-router-dom";

import { listTenants, setFirmHidden } from "../actions/adminActions";

import { setSubheaderText } from "../actions/navigateActions";

import CustomizableTable from "./Tables/CustomizableTable";
import InteractiveTableCell from "./Tables/InteractiveTableCell";

import "react-table/react-table.css";
import { humanFileSize, numberWithCommas } from "../utilities/utilities";

import buildData from "../buildData/buildData";

import lxwConstants from "../config/lexworkplace-constants";
const lexWorkplaceEditions = lxwConstants.lexWorkplaceEditions;

class Admin extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			tenants: [],
			dataUsage: {},
			dataUsageReportGenerated: null,
			filter: this.props.defaultTabFilterValue,
			loading: true,
		};
		this.columnConfig = [
			{ Header: "ID", accessor: "id", width: 60 },
			{ Header: "Customer ID", accessor: "customerID" },
			{
				Header: "Name",
				accessor: "name",
				width: 340,
				Cell: (row) => (
					<InteractiveTableCell
						menuItems={[
							{
								text: "Edit Firm",
								href: `admin/tenant/edit/${row.original.id}`,
							},
							{
								text:
									row.original.planType === 2
										? "Un-Hide Firm"
										: "Hide Firm",
								onSelect: () =>
									this.props.setFirmHidden(
										row.original.id,
										row.original.planType === 2 ? 0 : 1,
										this.refresh
									),
							},
						]}
					>
						<Link to={`admin/tenant/${row.original.id}/users`}>
							{row.original.name}
						</Link>
					</InteractiveTableCell>
				),
			},
			{
				Header: "Edition",
				accessor: "lxwEditionDisplay",
			},
			{ Header: "Active Users", accessor: "activeUserCount" },
			{
				Header: "Data Usage",
				accessor: "dataUsage",
				Cell: (row) =>
					row.value ? humanFileSize(row.value, true) : "",
			},
			{
				Header: "Data Usage Limit",
				accessor: "dataUsageLimitBytes",
				Cell: (row) => humanFileSize(row.value, true),
			},
			{
				Header: "Data Usage Over Limit",
				accessor: "dataUsageOverLimit",
				Cell: (row) =>
					row.value ? humanFileSize(row.value, true) : "",
			},
			{
				Header: "Documents",
				accessor: "documentCount",
				Cell: (row) => numberWithCommas(row.value),
			},
			{
				Header: "Emails",
				accessor: "emailCount",
				Cell: (row) => numberWithCommas(row.value),
			},
			{
				Header: "Matters",
				accessor: "matterCount",
				Cell: (row) => numberWithCommas(row.value),
			},
			{
				Header: "Search Index",
				accessor: "searchIndexDocs",
				Cell: (row) =>
					typeof row.value === "number"
						? numberWithCommas(row.value)
						: row.value,
			},
			{
				Header: "OCR",
				accessor: "ocrEnabled",
				Cell: (row) => (row.value === "On" ? "On" : ""),
			},
		];
		this.filterMap = {
			All: () => true,
			"Active Firms": (row) => {
				//Here planType 2 is being used as a placeholder for a "hide firm from actual firms view"
				return (
					row.id > 1000 &&
					row.planType !== 2 &&
					row.activeUserCount >= 1
				);
			},
			Customers: (row) => {
				return row.id > 1000 && row.planType !== 2;
			},
			Testing: (row) => {
				return row.id <= 1000;
			},
			"Inactive Firms": (row) => {
				return row.activeUserCount === null && row.planType !== 2;
			},
			Hidden: (row) => {
				return row.planType === 2;
			},
		};
		this.tabDescriptions = {
			All: "All Firms",
			"Active Firms": "Customers with at least 1 active user",
			Customers:
				"All non-hidden firms including those who have terminated service",
			Testing: "Testing Firms.  Those with tenantID < 1000",
			"Inactive Firms": "Un-hidden firms with 0 active users",
			Hidden: "Hidden firms, for mistakes",
		};
		this.generateCSV = this.generateCSV.bind(this);
		this.loadTenantData = this.loadTenantData.bind(this);
		this.refresh = this.refresh.bind(this);
	}
	generateCSV() {
		var data = this.state.tenants.filter(this.filterMap[this.state.filter]);

		let csvContent = "data:text/csv;charset=utf-8,";

		let headers = this.columnConfig.map((col) => col.Header);

		let accessors = this.columnConfig.map((col) => col.accessor);

		csvContent += headers.join(",") + "\r\n";

		const convertBytesToHumanColumns = [
			"dataUsage",
			"dataUsageLimitBytes",
			"dataUsageOverLimit",
		];

		let dataAsArray = data.map((col) => {
			if (col.id) {
				let csvRow = accessors
					.map((key) => {
						if (col[key]) {
							var str;
							if (convertBytesToHumanColumns.includes(key)) {
								str = "" + humanFileSize(col[key], true);
							} else {
								str = "" + col[key];
							}

							return `"${str.replace(/"/g, '""')}"`;
						} else {
							return null;
						}
					})
					.join(",");
				return csvRow;
			}
			return null;
		});

		csvContent += dataAsArray.join("\r\n");

		var encodedUri = encodeURI(csvContent);
		window.open(encodedUri);
	}
	componentDidMount() {
		this.props.setSubheaderText("LexWorkplace Firms");
		this.loadTenantData();
	}
	loadTenantData() {
		this.setState({ loading: true });
		listTenants().then((res) => {
			var tenants = res.mysql.tenants;
			var indices = {};
			var gotSearchIndexData = true;
			var dateReportGenerated = "Data usage report could not be loaded";
			var totalDataHuman = "???";

			if (res.mysql.esIndices) {
				res.mysql.esIndices.forEach((item) => {
					var tenantID = item.index.split("lexworkplace-t")[1];
					indices[tenantID] = { ...item };
				});
			} else {
				//if the search index is not connectable, res.mysql.esIndices will be null
				gotSearchIndexData = false;
			}
			if (res.mysql.dataUsage) {
				res.mysql.dataUsage.byFirm.forEach((s3prefix) => {
					if (tenants.find((t) => t.id === s3prefix.tenantID)) {
						tenants.find(
							(t) => t.id === s3prefix.tenantID
						).dataUsage = s3prefix.totalBytes;
					}
				});
				dateReportGenerated = new Date(
					res.mysql.dataUsage.info.reportFinished
				);
				totalDataHuman = res.mysql.dataUsage.aggregate.totalSizeHuman;
			}

			this.setState({
				loading: false,
				tenants: tenants.map((ten) => {
					const dataUsageLimitBytes =
						ten.tenantLimits && ten.tenantLimits.STORAGE_LIMIT_GB
							? 1000 *
								1000 *
								1000 *
								ten.tenantLimits.STORAGE_LIMIT_GB
							: 0;

					return {
						...ten,
						dataUsageLimitBytes,
						dataUsageOverLimit:
							ten.dataUsage > dataUsageLimitBytes
								? ten.dataUsage - dataUsageLimitBytes
								: null,
						searchIndexDocs: gotSearchIndexData
							? indices[ten.id]
								? parseInt(indices[ten.id]["docs.count"], 10)
								: "No Index"
							: "Could not Connect to ElasticSearch",
						lxwEditionDisplay: lexWorkplaceEditions.options[
							ten.lxwEdition
						]
							? lexWorkplaceEditions.options[ten.lxwEdition]
									.display
							: "???",
						ocrEnabled: ten.tenantFeatures.automaticOCR
							? "On"
							: "Off",
					};
				}),
				dataUsage: res.mysql.dataUsage,
				dataUsageReportGenerated: dateReportGenerated.toString(),
				totalDataHuman,
			});
		});
	}

	handleChange(key, value) {
		var newState = { [key]: value };
		this.setState(newState);
	}

	refresh() {
		this.loadTenantData();
	}
	render() {
		return (
			<div className="fullHeightFlex noDefaultMargin">
				<div style={{ padding: 8 }}>
					{" "}
					<div>
						Total Data Volume:
						{" " + this.state.totalDataHuman || ""}
					</div>
					<div>
						Data Usage Calculated:{" "}
						{this.state.dataUsageReportGenerated || ""}
					</div>
				</div>

				{this.state.tenants && (
					<CustomizableTable
						className="fullWidth"
						actionButtons={
							<div
								style={{
									display: "flex",
									gap: 8,
								}}
							>
								<DropDownButtonWithRouter
									title="Create New Firm"
									iconName="briefcase"
									pullRight
									menuItems={Object.keys(
										lexWorkplaceEditions.options
									).map((editionCode) => {
										const { display } =
											lexWorkplaceEditions.options[
												editionCode
											];
										return {
											text: `New ${display} Firm`,
											href: `/admin/tenant/new/${editionCode}`,
										};
									})}
								/>
								<DropDownButtonWithRouter
									title="Actions"
									iconName="gavel"
									pullRight
									menuItems={[
										{
											text: "Generate CSV of this View",
											onSelect: this.generateCSV,
										},
									]}
								/>
							</div>
						}
						loading={this.state.loading}
						tabsFilterMap={this.filterMap}
						tabDescriptions={this.tabDescriptions}
						defaultTabFilterValue={this.props.defaultTabFilterValue}
						tabFilterChanged={(newTabFilterName) => {
							this.setState({ filter: newTabFilterName });
						}}
						leftSideIcon="user"
						data={this.state.tenants}
						autoResize
						columns={this.columnConfig}
						noResultsText={"No items here"}
						tableUniqueID="/admin/firms"
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		defaultTabFilterValue:
			buildData.branch === "production" ? "Active Firms" : "Testing",
	};
};
export default connect(mapStateToProps, { setSubheaderText, setFirmHidden })(
	Admin
);
