import {} from "../actions/types";

const initialState = {
	loading: true,
	message: "",
	size: { w: 100, h: 100 },
	resizeElementID: null,
	resizeElementSize: { width: 100, height: 100 },
	allowUnderHeaderOverflow: true,
	forcePaginationVisible: false,
	scrollbarWidth: 0, // the scrollbar is a different size on mac vs windows
	launcherInstalled: window.localStorage.getItem("launcherInstalled")
		? window.localStorage.getItem("launcherInstalled") === "true"
		: false,
	launcherURL:
		navigator.platform.indexOf("Mac") > -1
			? "https://uptime-legalworks-public.s3.us-east-2.amazonaws.com/legalworks-desktop-launcher/LexWorkplace-Launcher.dmg"
			: "https://uptime-legalworks-public.s3.us-east-2.amazonaws.com/legalworks-desktop-launcher/LexWorkplace-Launcher-Setup.exe",
};

export default function (state = initialState, action) {
	if (action.type.match(/_REQUEST$/)) {
		return {
			...state,
			loading: true,
			message: action.payload ? action.payload.message : "",
		};
	}
	if (action.type.match(/_SUCCESS$/)) {
		return {
			...state,
			loading: false,
			message: "",
		};
	}
	if (action.type.match(/(_FAILURE|_CANCELED|_STARTED)$/)) {
		return {
			...state,
			loading: false,
			message: "",
		};
	}
	if (action.type.match(/_PROGRESS$/)) {
		return {
			...state,
			message: action.payload ? action.payload.message : "",
		};
	}
	if (action.type === "SET_SCREEN_SIZE") {
		return {
			...state,
			size: action.payload,
		};
	}
	if (action.type === "SET_RESIZE_ELEMENT_ID") {
		return {
			...state,
			resizeElementID: action.payload,
		};
	}
	if (action.type === "SET_RESIZE_ELEMENT_SIZE") {
		return {
			...state,
			resizeElementSize: action.payload,
		};
	}
	if (action.type === "SET_HEADER_OVERFLOW") {
		return {
			...state,
			allowUnderHeaderOverflow: action.payload,
		};
	}
	if (action.type === "SET_SCROLLBAR_WIDTH") {
		return {
			...state,
			scrollbarWidth: action.payload,
		};
	}
	if (action.type === "SET_LAUNCHER_INSTALLED") {
		return {
			...state,
			launcherInstalled: action.payload,
		};
	}
	if (action.type === "SET_FORCE_PAGINATION_VISIBLE") {
		return {
			...state,
			forcePaginationVisible: action.payload,
		};
	}

	return state;
}
