import React from "react";
import Select, { components } from "react-select";
import "./DropDownSelect.css";
import CreatableSelect from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@material-ui/core/CircularProgress";

import { AsyncSelectComponent } from "./AsyncSelect";

const maxCreateableLength = 255;

const options = [];

const customStyles = {
	option: (styles, { isDisabled }) => {
		return {
			...styles,
			color: isDisabled ? "hsl(0, 0%, 65%)" : styles.color,
			wordBreak: "break-word",
		};
	},
	indicatorsContainer: (styles, { isDisabled }) => ({
		...styles,
		":hover": {
			...styles[":hover"],
			pointerEvents: isDisabled ? "none" : "auto", // disable pointer events for indicator
		},
	}),
	container: (styles) => ({
		...styles,
		pointerEvents: "auto", // set to none if disabled which prevent cursor not-allowed
	}),
	multiValue: (base, state) => {
		return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
	},
	multiValueLabel: (base, state) => {
		return state.data.isFixed
			? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
			: base;
	},
	multiValueRemove: (base, state) => {
		return state.data.isFixed ? { ...base, display: "none" } : base;
	},
	control: (styles, { isDisabled }) => ({
		...styles,
		backgroundColor: isDisabled ? "#eee" : "white",
		borderColor: isDisabled ? "#ccc" : styles.borderColor,
		cursor: isDisabled ? "not-allowed" : "default",
		":hover": {
			borderColor: isDisabled ? "#ccc" : styles.borderColor,
		},
	}),
	placeholder: (styles, { isDisabled }) => ({
		...styles,
		color: isDisabled ? "#555" : styles.color,
	}),
};

const orderOptions = (values) => {
	return values && values.filter
		? values
				.filter((v) => v.isFixed)
				.concat(values.filter((v) => !v.isFixed))
		: values;
};

const LoadingIndicator = () => {
	return (
		<div style={{ position: "absolute", top: 4, right: 42 }}>
			<CircularProgress size={28} thickness={5} />
		</div>
	);
};

const LimitedValueContainerFactory = (
	maxToShow = 20,
	overLimitDisplayNoun = "item"
) => {
	const LimitedValueContainer = ({ children, getValue, ...props }) => {
		/*without limiting the number of items in the box, 
		It will keep expanding in height forever and eventually gets unresponsive
		*/

		var length = getValue().length;
		let displayChips = Array.isArray(children[0])
			? children[0].filter((item, index) => index < maxToShow)
			: children[0];
		let shouldBadgeShow = length > maxToShow;
		let displayLength = length - maxToShow;

		let badge = (
			<div className="selectValueContainerRoot">
				{shouldBadgeShow &&
					`+ ${displayLength} ${overLimitDisplayNoun}${
						length !== 1 ? "s" : ""
					} selected`}
			</div>
		);
		var newKids = [displayChips, children[1]];

		return (
			<components.ValueContainer {...props}>
				{newKids}
				{badge}
			</components.ValueContainer>
		);
	};
	return LimitedValueContainer;
};

export class DropDownSelect extends React.Component {
	state = {
		selectedOption: this.props.defaultValue,
	};
	calculateIsClearable = () => {
		if (this.state.selectedOption && this.state.selectedOption.some) {
			return (
				this.props.isClearable &&
				this.state.selectedOption.some((v) => !v.isFixed)
			);
		} else {
			return this.props.isClearable;
		}
	};
	handleChange = (selectedOption, actionMeta) => {
		switch (actionMeta.action) {
			case "remove-value":
			case "pop-value":
				if (
					actionMeta.removedValue &&
					actionMeta.removedValue.isFixed
				) {
					return;
				}
				break;
			case "clear":
				selectedOption = this.props.options.filter((v) => v.isFixed);
				break;
			default:
				break;
		}

		selectedOption = orderOptions(selectedOption);
		//   this.setState({ value: value });

		this.setState({ selectedOption });
		this.props.handler && this.props.handler(selectedOption);
	};

	render() {
		let componentsMixin = {
			LoadingIndicator,
			...this.props.components,
		};
		if (this.props.isCreatable) {
			if (this.props.isAsync) {
				return (
					<AsyncSelectComponent
						{...this.props}
						menuPlacement="auto"
						className={
							"DropDownSelect " + (this.props.className || "")
						}
						isClearable={this.calculateIsClearable()}
						isCreatable
						styles={customStyles}
						isMulti={this.props.isMulti}
						onChange={this.handleChange}
						options={this.props.options || options}
						defaultValue={this.props.defaultValue}
						placeholder={this.props.placeholder || "Select..."}
						isValidNewOption={(
							inputValue,
							event,
							filteredOptionsBasedOnInput
						) => {
							// check if the input value is already in the list to prevent duplication
							const matchingValue =
								filteredOptionsBasedOnInput.find((x) => {
									// if the value is an integer it does not have .toLowerCase() method
									if (
										x &&
										x.value &&
										typeof x.value === "string"
									) {
										return (
											x.value.toLowerCase() ===
											inputValue.toLowerCase()
										);
									}
								});
							if (matchingValue) return false;
							// increase character limit to 255
							return (
								inputValue.length <= maxCreateableLength &&
								inputValue.length > 0
							);
						}}
						noOptionsMessage={(value) => {
							if (
								value.inputValue.length >= maxCreateableLength
							) {
								if (this.props.label) {
									return `Input exceeds maximum length allowed for ${this.props.label}`;
								}
								return "Input exceeds maximum length allowed";
							}
							return "No options";
						}}
						components={componentsMixin}
					/>
				);
			} else {
				return (
					<CreatableSelect
						{...this.props}
						menuPlacement="auto"
						className={
							"DropDownSelect " + (this.props.className || "")
						}
						isClearable={this.calculateIsClearable()}
						styles={customStyles}
						isMulti={this.props.isMulti}
						onChange={this.handleChange}
						options={this.props.options || options}
						defaultValue={this.props.defaultValue}
						placeholder={this.props.placeholder || "Select..."}
						isValidNewOption={(
							inputValue,
							event,
							filteredOptionsBasedOnInput
						) => {
							// check if the input value is already in the list to prevent duplication
							const matchingValue =
								filteredOptionsBasedOnInput.find(
									(x) =>
										x.value.toLowerCase() ===
										inputValue.toLowerCase()
								);
							if (matchingValue) return false;
							// increase character limit to 255
							return (
								inputValue.length <= maxCreateableLength &&
								inputValue.length > 0
							);
						}}
						noOptionsMessage={(value) => {
							if (
								value.inputValue.length >= maxCreateableLength
							) {
								if (this.props.label) {
									return `Input exceeds maximum length allowed for ${this.props.label}`;
								}
								return "Input exceeds maximum length allowed";
							}
							return "No options";
						}}
						components={componentsMixin}
					/>
				);
			}
		} else {
			if (this.props.isAsync) {
				const limitNumber = this.props.limitNumber;

				if (limitNumber) {
					componentsMixin.ValueContainer =
						LimitedValueContainerFactory(
							limitNumber,
							this.props.overLimitDisplayNoun
						);
				}

				return (
					<AsyncSelectComponent
						{...this.props}
						menuPlacement="auto"
						className={
							"DropDownSelect " + (this.props.className || "")
						}
						isClearable={this.calculateIsClearable()}
						styles={customStyles}
						isMulti={this.props.isMulti}
						onChange={this.handleChange}
						options={this.props.options || options}
						defaultValue={this.props.defaultValue}
						placeholder={this.props.placeholder || "Select..."}
						components={componentsMixin}
					/>
				);
			} else {
				return (
					<Select
						{...this.props}
						menuPlacement="auto"
						className={
							"DropDownSelect " + (this.props.className || "")
						}
						isClearable={this.calculateIsClearable()}
						styles={customStyles}
						isMulti={this.props.isMulti}
						onChange={this.handleChange}
						options={this.props.options.slice(0, 40) || options}
						defaultValue={this.props.defaultValue}
						placeholder={this.props.placeholder || "Select..."}
						components={componentsMixin}
					/>
				);
			}
		}
	}
}
export class LabeledDropDownSelect extends React.Component {
	handler(selectedOption) {
		if (this.props.handler) {
			this.props.handler(this.props.name, selectedOption);
		}
	}
	render() {
		return (
			<div
				className={
					"LabeledInput LabeledDropDownSelect " +
					(this.props.className || "")
				}
			>
				<div style={{ display: "flex" }}>
					<div className="InputLabel">{this.props.label}</div>
					{this.props.hoverText && (
						<div title={this.props.hoverText}>
							<FontAwesomeIcon
								icon="info-circle"
								style={{ marginLeft: 4, marginTop: 2 }}
							/>
						</div>
					)}
				</div>
				<DropDownSelect
					{...this.props}
					isCreatable={this.props.isCreatable}
					isClearable={this.props.isClearable}
					isEmail={this.props.isEmail}
					isValidNewOption={this.props.isValidNewOption}
					options={this.props.options}
					defaultValue={this.props.defaultValue}
					placeholder={this.props.placeholder || "Select..."}
					isMulti={this.props.isMulti}
					handler={this.handler.bind(this)}
				/>
			</div>
		);
	}
}
