import API from "../utilities/LocalApiProxy";
import {
	GET_CLIO_INTEGRATION_SETTINGS_REQUEST,
	GET_CLIO_INTEGRATION_SETTINGS_SUCCESS,
	GET_CLIO_INTEGRATION_SETTINGS_FAILURE,
	SET_CLIO_INTEGRATION_SETTINGS_REQUEST,
	SET_CLIO_INTEGRATION_SETTINGS_SUCCESS,
	SET_CLIO_INTEGRATION_SETTINGS_FAILURE,
} from "./types";
export function getEWSUrl() {
	return function (dispatch) {
		dispatch({ type: "GET_EWS_URL_REQUEST" });
		API.get("/ews/exchangeServer")
			.then((res) => {
				console.log(res.mysql);
				dispatch({
					type: "GET_EWS_URL_SUCCESS",
					payload: res.mysql[0].exchangeUrl || "",
				});
			})
			.catch(() => {
				dispatch({ type: "GET_EWS_URL_FAILURE" });
			});
	};
}
export function setEWSUrl(exchangeUrl) {
	return function (dispatch) {
		dispatch({ type: "SET_EWS_URL_REQUEST" });
		API.post("/ews/exchangeServer", { body: { exchangeUrl } })
			.then(() => {
				dispatch({ type: "SET_EWS_URL_SUCCESS" });
				window.globalHistory.push("/settings");
			})
			.catch(() => {
				dispatch({ type: "SET_EWS_URL_FAILURE" });
			});
	};
}
export function unmountEWSPage() {
	return function (dispatch) {
		dispatch({ type: "UNMOUNT_EWS_PAGE" });
	};
}

export function getClioIntegrationSettingStatus() {
	return function (dispatch) {
		dispatch({ type: GET_CLIO_INTEGRATION_SETTINGS_REQUEST });
		API.get("/integrations/clio")
			.then((res) => {
				dispatch({
					type: GET_CLIO_INTEGRATION_SETTINGS_SUCCESS,
					payload:
						res.mysql.length && res.mysql[0].status
							? res.mysql[0].status
							: 0,
				});
			})
			.catch(() => {
				dispatch({ type: GET_CLIO_INTEGRATION_SETTINGS_FAILURE });
			});
	};
}

export function setClioIntegrationSettingStatus(clioIntegrationSettingStatus) {
	return function (dispatch) {
		const status = clioIntegrationSettingStatus ? 1 : 0;
		dispatch({ type: SET_CLIO_INTEGRATION_SETTINGS_REQUEST });
		API.post("/integrations/clio", {
			body: { clioIntegrationSettingStatus: status },
		})
			.then(() => {
				dispatch({
					type: SET_CLIO_INTEGRATION_SETTINGS_SUCCESS,
					payload: status,
				});
			})
			.catch((err) => {
				dispatch({
					type: SET_CLIO_INTEGRATION_SETTINGS_FAILURE,
					payload: status ? 0 : 1,
				});
			});
	};
}
