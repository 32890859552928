export const numberOptions = [10, 20, 30, 40, 50].map(function (x) {
	return { value: x, label: "" + x };
});
export const matterTypeOptions = [
	"(None)",
	"Administrative Law",
	"Alternative Dispute Resolution",
	"Antitrust",
	"Appellate",
	"Banking",
	"Bankrupcty",
	"Business Law",
	"Civil Litigation",
	"Civil Rights",
	"Collections",
	"Corporate Law",
	"Criminal Law",
	"Divorce",
	"Employment Law",
	"Estate Planning",
	"Family Law",
	"General Practice",
	"Government",
	"Health Law",
	"Immigration",
	"In-House Counsel",
	"Insurance Law",
	"Intellectual Property",
	"International Law",
	"Labor Law",
	"Malpractice",
	"Mergers & Acquisitions",
	"Personal Injury",
	"Probate",
	"Product Liability",
	"Real Estate",
	"Securities Law",
	"Tax Law",
	"Technology Law",
	"Trust & Estates",
	"Workers Compensation",
	"Wrongful Death",
	"Wrongful Termination",
];

export const documentTagOptions = [
	"For Review",
	"Urgent",
	"On Hold",
	"Template",
	"Personal",
	"Do Not Destroy",
	"Reference",
];

export const defaultClientTags = [
	"Business",
	"Government",
	"Individual",
	"Trust",
	"Class-Action",
];

export const matterTagOptions = [
	"Litigation",
	"Minnesota",
	"Wisconsin",
	"New York",
	"European Union",
	"China",
	"Pre-Merger",
];

export const officeBranchOptions = ["(None)", "Main Office"];

export const clientStatusOptions = [
	{ value: "active", label: "Active" },
	{ value: "inactive", label: "Inactive" },
];

export const clientStatusLookup = { active: 0, inactive: 1 };
