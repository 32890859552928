import React from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
const RowCheckbox = withStyles({
	root: {
		padding: 0,
	},
})((props) => (
	<Checkbox
		color="primary"
		{...props}
		style={{ left: 4 }}
		icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
		checkedIcon={<CheckBoxIcon fontSize="small" />}
	/>
));
export { RowCheckbox };
