import React, { Component } from "react";
import { connect } from "react-redux";
import { components } from "react-select";

import KeyboardEventHandler from "react-keyboard-event-handler";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
	closeGlobalDialog,
	globalDialogSubmit,
	globalDialogValidate,
} from "../actions/globalEditDialogActions";
import { ButtonWithIcon } from "./ButtonWithIcon";
import { LabeledTextInput } from "./TextInput";

import "./EditDialog.css";
import { LabeledDropDownSelect } from "./DropDownSelect";
import { LabeledTextArea } from "./TextArea";

import { SmallNoPaddingIconButton } from "./ButtonWithIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClioMatterSelector } from "./ClioMatterSelector";

import { deleteExternalEmailAddressForUser } from "../actions/shareActions";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import BulkMatterPermissions from "./BulkMatterPermissions";

class EditDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			value: "",
			textAreaContent: "",
			recipients: null,
			convertToPDFAsNewVersion: true,
		};
		this.formRef = React.createRef();
		this.handleChange = this.handleChange.bind(this);
		this.submit = this.submit.bind(this);
		this.externalShareComponent = this.externalShareComponent.bind(this);

		this.inputFieldComponent = this.inputFieldComponent.bind(this);
		this.switchComponentType = this.switchComponentType.bind(this);
		this.copyValueComponent = this.copyValueComponent.bind(this);
		this.extraButtonField = this.extraButtonField.bind(this);
	}
	static getDerivedStateFromProps(props, state) {
		if (state.loading && !props.loading) {
			const newState = {
				loading: false,
				textAreaContent: "",
			};
			return newState;
		} else {
			return null;
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.dialogOpen !== prevProps.dialogOpen) {
			this.setState({
				value: this.props.value || "",
				textAreaContent: "",
				recipients: null,
			});

			if (
				this.props.dialogOpen &&
				this.formRef.current &&
				this.formRef.current.textInput
			) {
				//if the dialog is newly open, heavy handedly grab focus after 5ms
				setTimeout(() => {
					this.formRef.current.textInput.focus();
				}, 5);
			}
		}
	}

	handleChange(key, value) {
		this.setState({ [key]: value }, () => {
			this.props.globalDialogValidate(this.state);
		});
	}
	submit() {
		if (this.props.canSubmit) {
			this.props.globalDialogSubmit &&
				this.props.globalDialogSubmit(this.state);
		}
	}
	externalShareComponent() {
		const OptionWithDeleteButton = (props) => {
			const { isFocused, data } = props;
			return (
				<span style={{}}>
					<div
						style={{
							position: "absolute",
							right: 12,
							paddingTop: 7,
						}}
					>
						{isFocused ? (
							<div>
								<SmallNoPaddingIconButton
									title="delete email address from my saved list"
									onClick={() =>
										this.props.deleteExternalEmailAddressForUser(
											data.value
										)
									}
								>
									<FontAwesomeIcon
										style={{ fontSize: "1rem" }}
										icon={"trash"}
									/>
								</SmallNoPaddingIconButton>
							</div>
						) : null}
					</div>
					<components.Option {...props} />
				</span>
			);
		};
		return (
			<div>
				<div style={{ maxWidth: "440px" }}>
					<LabeledDropDownSelect
						label="Recipient(s)"
						isMulti
						name="recipients"
						isCreatable
						isEmail={true}
						isAsync
						styles={{
							option: (base) => ({
								...base,
								border: `1px dotted blue`,
								height: "100%",
							}),
						}}
						options={
							this.props.emailAddressListLoading
								? []
								: this.props.externalEmailAddresses.map(
										(e) => ({
											label: e,
											value: e,
										})
									)
						}
						isLoading={this.props.emailAddressListLoading}
						defaultValue={[]}
						disable={this.canSubmit}
						handler={this.handleChange}
						components={{ Option: OptionWithDeleteButton }}
					/>
				</div>
				<div>
					<LabeledTextArea
						name={"textAreaContent"}
						label={"Message"}
						onChange={this.handleChange}
						defaultValue={this.props.textAreaContent}
					/>
				</div>
			</div>
		);
	}

	inputFieldComponent() {
		return (
			<LabeledTextInput
				label={this.props.textAreaLabel || ""}
				name="value"
				formRef={this.formRef}
				autoFocus
				placeholder={this.props.placeHolder || this.props.label}
				defaultValue={this.props.value}
				onChange={this.handleChange}
				onEnterKey={this.submit}
				disabled={this.props.readOnly}
			/>
		);
	}
	copyValueComponent() {
		return (
			<div>
				<pre
					style={{
						marginRight: 8,
						whiteSpace: "pre-wrap",
						wordWrap: "break-word",
					}}
				>
					{this.props.value}
				</pre>
				<CopyToClipboard
					onCopy={this.props.closeGlobalDialog}
					text={this.props.value}
				>
					<button style={{ height: 39, borderRadius: 4 }}>
						Copy
					</button>
				</CopyToClipboard>
			</div>
		);
	}
	convertPDFTODocPopUpOptions() {
		return (
			<div>
				<ButtonWithIcon
					style={{
						whiteSpace: "pre-wrap",
						wordWrap: "break-word",
						display: "block",
					}}
					buttonText={`Create a New PDF Named "${this.props.value.newDocName}"`}
					className="blue"
					iconName={this.props.submitIcon || "check"}
					handler={() => {
						this.setState(
							{
								convertToPDFAsNewVersion: false,
							},
							this.submit
						);
					}}
				/>
				<ButtonWithIcon
					style={{
						marginTop: 5,
						whiteSpace: "pre-wrap",
						wordWrap: "break-word",
						display: "block",
					}}
					buttonText={`Save this PDF as a New Version in "${this.props.value.fileName}"`}
					className="blue"
					disabled={this.props.value.isDocumentInUse}
					iconName={this.props.submitIcon || "check"}
					handler={() => {
						this.setState(
							{
								convertToPDFAsNewVersion: true,
							},
							this.submit
						);
					}}
				/>
				<div>
					<ButtonWithIcon
						handler={this.props.closeGlobalDialog}
						buttonText={this.props.cancelButtonLabel || "Cancel"}
						iconName="times"
						style={{
							marginTop: 20,
						}}
					/>
				</div>

				{this.props.value.isDocumentInUse ? (
					<div style={{ marginTop: 10 }}>
						{this.props.value.documentInUseMessage}
					</div>
				) : null}
			</div>
		);
	}
	switchComponentType() {
		if (this.props.type === "email") {
			return this.externalShareComponent();
		} else if (this.props.type === "clioDropdown") {
			return (
				<ClioMatterSelector
					name="clioMatterList"
					label={this.props.label}
					clioMattersList={this.props.clioMattersList}
					handler={this.handleChange}
				/>
			);
		} else if (this.props.type === "copyValue") {
			return this.copyValueComponent();
		} else if (this.props.type === "BulkMatterPermissions") {
			return (
				<BulkMatterPermissions
					{...this.props.bulkMatterPermissionsObject}
					onChange={this.handleChange}
				/>
			);
		} else {
			if (this.props.hideTextfield) {
				return null;
			} else {
				return this.inputFieldComponent();
			}
		}
	}

	extraButtonField() {
		if (this.props.type === "docTOPDF") {
			return this.convertPDFTODocPopUpOptions();
		} else if (this.props.type === "ocrLimitWarning") {
			return (
				<div style={{ marginLeft: "-15px", marginTop: "5px" }}>
					<Checkbox
						color="primary"
						style={{ left: 4 }}
						icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
						checkedIcon={<CheckBoxIcon fontSize="small" />}
						onChange={(event) => {
							if (event.target.checked) {
								let timestamp = new Date().toJSON();
								window.localStorage.setItem(
									"ignoreOverOcrLimitWarning",
									timestamp
								);
							} else {
								window.localStorage.removeItem(
									"ignoreOverOcrLimitWarning"
								);
							}
						}}
					/>
					Ignore OCR credit warnings for the rest of the month
				</div>
			);
		}
	}
	render() {
		if (!this.props.dialogOpen) {
			return null;
		} else {
			if (this.state.loading) {
				return (
					<div className="EditDialogBlocker">
						<div className="EditDialog">
							<h1>
								Loading<span className="Loading">...</span>
							</h1>
						</div>
					</div>
				);
			} else {
				return (
					<div className="EditDialogBlocker">
						<div
							className={
								this.props.extraCSSClass
									? this.props.extraCSSClass
									: "EditDialog"
							}
						>
							<KeyboardEventHandler
								handleKeys={["esc"]}
								onKeyEvent={() => {
									this.props.closeGlobalDialog();
								}}
							/>
							<div>
								<h3>{this.props.label}</h3>
								<div
									style={{
										marginBottom: this.extraButtonField
											? 2
											: 20,
										marginTop: 4,
										whiteSpace: "pre-wrap",
										wordWrap: "break-word",
										display: "block",
									}}
								>
									{this.props.mainText}
								</div>

								<div>
									<KeyboardEventHandler
										handleKeys={["esc"]}
										onKeyEvent={() => {
											this.props.closeGlobalDialog();
										}}
									>
										<div style={{ display: "flex" }}>
											<div style={{ flexGrow: 1 }}>
												{this.switchComponentType()}
											</div>
											<div
												style={{
													marginLeft: 8,
													paddingTop: 8,
												}}
											>
												{this.props.suffix &&
													`.${this.props.suffix}`}
											</div>
										</div>
									</KeyboardEventHandler>
								</div>
							</div>
							{this.props.errorMessage ? (
								<div className="inlineErrorIndicator">
									{this.props.errorMessage}
								</div>
							) : null}

							<div style={{ marginTop: 10 }}>
								{this.props.hideCancelButton ? null : (
									<ButtonWithIcon
										handler={this.props.closeGlobalDialog}
										buttonText={
											this.props.cancelButtonLabel ||
											"Cancel"
										}
										iconName="times"
										style={{ marginRight: 8 }}
									/>
								)}
								{this.props.readOnly ? null : (
									<ButtonWithIcon
										handler={this.submit.bind(this)}
										buttonText={
											this.props.submitButtonLabel
										}
										disabledHoverText={
											this.props
												.disabledHoverTextForSubmitButton
										}
										disabled={!this.props.canSubmit}
										className="blue"
										iconName={
											this.props.submitIcon || "check"
										}
									/>
								)}
								<div style={{ marginTop: 5 }}>
									{this.extraButtonField()}
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}
const mapStateToProps = (state) => {
	return {
		matterDetails: state.matter.matterDetails,
		loading: state.matter.newEditMatterLoading,
		dialogOpen: state.globalDialog.dialogOpen,
		label: state.globalDialog.label,
		mainText: state.globalDialog.mainText,
		value: state.globalDialog.value,
		canSubmit: state.globalDialog.canSubmit,
		errorMessage: state.globalDialog.errorMessage,
		submitIcon: state.globalDialog.submitIcon,
		placeHolder: state.globalDialog.placeHolder,
		textAreaLabel: state.globalDialog.textAreaLabel,
		submitButtonLabel: state.globalDialog.submitButtonLabel,
		cancelButtonLabel: state.globalDialog.cancelButtonLabel,
		readOnly: state.globalDialog.readOnly,
		suffix: state.globalDialog.suffix,
		hideTextfield: state.globalDialog.hideTextfield,
		clioMattersList: state.clioIntegration.clioMatterListData,
		clioMatterListStatus: state.clioIntegration.clioStatus,
		recipients: state.globalDialog.recipients,
		textAreaContent: state.globalDialog.textAreaContent,
		textAreaName: state.globalDialog.textAreaName,
		type: state.globalDialog.type,
		hideCancelButton: state.globalDialog.hideCancelButton,
		externalEmailAddresses: state.user.externalEmailAddresses,
		emailAddressListLoading: state.user.emailAddressListLoading,
		extraCSSClass: state.globalDialog.extraCSSClass,
		bulkMatterPermissionsObject:
			state.globalDialog.bulkMatterPermissionsObject,
		disabledHoverTextForSubmitButton:
			state.globalDialog.disabledHoverTextForSubmitButton,
	};
};
EditDialog.propTypes = {};

export default connect(mapStateToProps, {
	closeGlobalDialog,
	globalDialogSubmit,
	globalDialogValidate,
	deleteExternalEmailAddressForUser,
})(EditDialog);
