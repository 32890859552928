import {
	FETCH_MATTER_DETAIL_SUCCESS,
	FETCH_MATTER_DETAIL_FAILURE,
	EDIT_MATTER,
	FETCH_MATTER_LIST_SUCCESS,
	FETCH_MATTERS_LIST_SUCCESS,
	FETCH_MATTER_TAGS_SUCCESS,
	FETCH_MATTER_TYPES_SUCCESS,
	FETCH_OFFICE_BRANCH_SUCCESS,
	FETCH_ALL_USERS_SUCCESS,
	GET_EVERYTHING_FOR_NEW_EDIT_MATTER_REQUEST,
	GET_EVERYTHING_FOR_NEW_MATTER_SUCCESS,
	GET_EVERYTHING_FOR_EDIT_MATTER_SUCCESS,
	UNMOUNT_NEW_EDIT_MATTER,
	UNMOUNT_MATTER_DETAIL,
	SET_EDIT_MATTER_REDIRECT_LOCATION,
	CLOSE_MATTER_SUCCESS,
	REOPEN_MATTER_SUCCESS,
	LINK_CLIO_MATTER_SUCCESS,
	UNLINK_CLIO_MATTER_SUCESS,
} from "../actions/types";

const initialState = {
	matterListData: [],
	documentsList: [],
	matterDetails: { primaryAttorneys: [], tags: [] },
	availableClients: [],
	availableMatterTags: [],
	availableAssignedStaff: [],
	availableMatterTypes: [],
	availableOfficeBranches: [],
	newEditMatterLoading: true,
	matterDetailsLoading: true,
	editMatterRedirectLocation: "/matters",
	matterPermissions: {},
	permissionsGroups: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_MATTER_DETAIL_SUCCESS:
			return {
				...state,
				matterDetails: action.payload.matterDetails,
				matterDetailsLoading: false,
			};
		case FETCH_MATTER_DETAIL_FAILURE:
			return {
				...state,
			};
		case FETCH_MATTER_LIST_SUCCESS:
			return {
				...state,
				matterListData: action.payload.matterListData,
			};
		case CLOSE_MATTER_SUCCESS:
			return {
				...state,
				matterDetails: {
					...state.matterDetails,
					dateClosed: action.payload.closedDate,
				},
			};
		case REOPEN_MATTER_SUCCESS:
			return {
				...state,
				matterDetails: {
					...state.matterDetails,
					dateClosed: action.payload.closedDate,
				},
			};
		case GET_EVERYTHING_FOR_NEW_EDIT_MATTER_REQUEST:
			return {
				...state,
				matterDetails: initialState.matterDetails,
				newEditMatterLoading: true,
			};
		case GET_EVERYTHING_FOR_NEW_MATTER_SUCCESS:
			return {
				...state,
				availableMatterTags: action.payload.availableMatterTags,
				availableAssignedStaff: action.payload.availableAssignedStaff,
				availableClients: action.payload.availableClients,
				availableMatterTypes: action.payload.availableMatterTypes,
				availableOfficeBranches: action.payload.availableOfficeBranches,
				permissionsGroups: action.payload.permissionsGroups,
				matterDetails: { primaryAttorneys: [], tags: [] },
				//matterDetails: action.payload.matterDetails,
				newEditMatterLoading: false,
			};
		case GET_EVERYTHING_FOR_EDIT_MATTER_SUCCESS:
			return {
				...state,
				...action.payload,
				newEditMatterLoading: false,
			};
		case UNMOUNT_NEW_EDIT_MATTER:
			return {
				...state,
				matterDetails: initialState.matterDetails,
				newEditMatterLoading: true,
			};
		case UNMOUNT_MATTER_DETAIL:
			return {
				...state,
				matterDetails: initialState.matterDetails,
				matterDetailsLoading: true,
			};
		case FETCH_MATTERS_LIST_SUCCESS:
			return {
				...state,
				mattersList: action.payload,
			};
		case EDIT_MATTER:
			return {
				...state,
				info: { horse: 2, ujje: "Hamie noo" },
			};
		case FETCH_MATTER_TAGS_SUCCESS:
			return {
				...state,
				availableMatterTags: action.payload.tags,
			};
		case FETCH_MATTER_TYPES_SUCCESS:
			return {
				...state,
				availableMatterTypes: action.payload.tags,
			};
		case FETCH_OFFICE_BRANCH_SUCCESS:
			return {
				...state,
				availableOfficeBranches: action.payload.tags,
			};
		case FETCH_ALL_USERS_SUCCESS:
			return {
				...state,
				availablePrimaryAttorneys:
					action.payload.availablePrimaryAttorneys,
			};
		case SET_EDIT_MATTER_REDIRECT_LOCATION:
			return {
				...state,
				editMatterRedirectLocation: action.payload.url,
			};
		case LINK_CLIO_MATTER_SUCCESS:
			if (action.payload) {
				return {
					...state,
					matterDetails: {
						...state.matterDetails,
						clioMatterID: action.payload.clioMatterId,
					},
				};
			} else return state;
		case UNLINK_CLIO_MATTER_SUCESS:
			if (action.payload) {
				return {
					...state,
					matterDetails: {
						...state.matterDetails,
						clioMatterID: undefined,
					},
				};
			} else return state;
		default:
			return state;
	}
}
