import React, { Component } from "react";
import { connect } from "react-redux";

import { runSearchQuery } from "../actions/searchActions";
import { getEverythingForSearchPage } from "../actions/searchActions";
import { getSignedUrlAndDownload } from "../actions/documentActions";
import { setForcePagination } from "../actions/globalScreenSizeActions";
import { setComponent } from "../actions/globalPaginationActions";

import { LoadingBlocker } from "./UnderSubHeader";

import { SearchResults, AggregationResults } from "./SearchResults";

import { LabeledTextInput } from "./TextInput";
import { ButtonWithIcon } from "./ButtonWithIcon";

import { LabeledDropDownSelect } from "./DropDownSelect";

import { LabeledDatePicker } from "./LabeledDatePicker";

import { withStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { setSubheaderText } from "../actions/navigateActions";

import queryString from "query-string";

import config from "../config/legalworks.frontend.config";

const RowCheckbox = withStyles({
	root: {
		padding: 0,
	},
})((props) => <Checkbox color="primary" {...props} />);

const recordTypeMap = {
	docs: { display: "Document" },
	email: { display: "Email" },
	matterNote: { display: "Matter Note" },
};

const recordTypeOptions = [
	{
		label: "Documents (File Name and Content)",
		value: "docs",
		id: 0,
	},
	{
		label: "Documents (File Name)",
		value: "docFileName",
		id: 3,
	},
	{
		label: "Emails",
		value: "email",
		id: 1,
	},
	{
		label: "Matter Notes",
		value: "matterNote",
		id: 2,
	},
];
const orderingOptions = [
	{
		label: "Last Modified Date (Newest First)",
		value: "modifiedDateDescending",
		id: 0,
	},
	{
		label: "Last Modified Date (Oldest First)",
		value: "modifiedDateAscending",
		id: 1,
	},
	{
		label: "Relevance",
		value: "relevance",
		id: 2,
	},
];
const userOperationOptions = [
	{ label: "Modfied a Document", value: "documentModified", id: 0 },
	{ label: "Has Document Locked", value: "documentLocked", id: 1 },
];
const makeDefaultRecordTypeSelection = (recordTypeFilter) => {
	return recordTypeOptions.filter(
		(allowedOption) =>
			recordTypeFilter &&
			recordTypeFilter.find(
				(providedFilter) => providedFilter.id === allowedOption.id
			)
	);
};

const makeDefaultUsersFilterSelection = (users, usersFilterByID) => {
	var selectedItems = [];
	usersFilterByID &&
		usersFilterByID.forEach((userFilter) => {
			var thisUser = users.filter((u) => u.id === userFilter.id);
			if (thisUser.length) {
				selectedItems.push({
					label: thisUser[0].displayName,
					value: thisUser[0].sub,
					id: thisUser[0].id,
				});
			}
		});
	return selectedItems.length ? selectedItems : null;
};

const makeDefaultSelection = (documentTypeNames, documentTypeFilter) => {
	var selectedItems = [];
	documentTypeFilter &&
		documentTypeFilter.forEach((typeID) => {
			if (documentTypeNames[typeID.id]) {
				selectedItems.push({
					id: typeID.id,
					label: documentTypeNames[typeID.id],
					value: documentTypeNames[typeID.id],
				});
			}
		});
	return selectedItems;
};
const makeDefaultMatterFilterSelection = (mattersList, matterFilter) => {
	const allMatters = mattersList;
	var defaultSelection = [];
	matterFilter &&
		matterFilter.forEach((m) => {
			var matterName = allMatters.filter((list) => list.id === m.id);
			if (matterName.length) {
				var config = {
					id: m.id,
					label: matterName[0].name,
					value: matterName[0].name,
				};
				defaultSelection.push(config);
			}
		});
	return defaultSelection;
};
const makeDefaultOrderingSelection = (orderingValue) => {
	return orderingValue
		? orderingOptions.filter(
				(allowedOption) => allowedOption.id === orderingValue.id
			)[0]
		: orderingOptions[0];
};

const queryStringMap = {
	runAfterNavigating: () => {
		return {
			runAfterNavigating: true,
		};
	},
	q: (query) => ({ query }),
	m: (ids) => ({
		matterFilter: ids
			.split(":")
			.filter((id) => {
				return id.match(/-?\d+/);
			})
			.map((id) => ({ id: parseInt(id, 10) })),
	}),
	docType: (ids) => ({
		documentTypeFilter: ids
			.split(":")
			.filter((id) => {
				return id.match(/^\d+$/);
			})
			.map((id) => ({ id: parseInt(id, 10) })),
	}),
	docStatus: (ids) => ({
		documentStatusFilter: ids
			.split(":")
			.filter((id) => {
				return id.match(/^\d+$/);
			})
			.map((id) => ({ id: parseInt(id, 10) })),
	}),
	docTags: (ids) => ({
		documentTagsFilter: ids
			.split(":")
			.filter((id) => {
				return id.match(/^\d+$/);
			})
			.map((id) => ({ id: parseInt(id, 10) })),
	}),
	users: (ids) => {
		return {
			usersFilter: ids
				.split(":")
				.filter((id) => {
					return id.match(/^\d+$/);
				})
				.map((id) => ({ id: parseInt(id, 10) })),
		};
	},
	lock: (ids) => {
		return {
			usersLockFilter: ids
				.split(":")
				.filter((id) => {
					return id.match(/^\d+$/);
				})
				.map((id) => ({ id: parseInt(id, 10) })),
		};
	},
	userOp: (ids) => {
		var matchingUserOps = [];

		ids.split(":")
			.map((id) => parseInt(id, 10))
			.forEach((id) => {
				const findAttempt = userOperationOptions.find(
					(allowedType) => allowedType.id === id
				);
				if (findAttempt) {
					matchingUserOps.push(findAttempt);
				}
			});
		return { userOperationFilter: matchingUserOps };
	},
	type: (ids) => {
		var matchingRecordTypes = [];

		ids.split(":")
			.map((id) => parseInt(id, 10))
			.forEach((id) => {
				const findAttempt = recordTypeOptions.find(
					(allowedType) => allowedType.id === id
				);
				if (findAttempt) {
					matchingRecordTypes.push(findAttempt);
				}
			});
		return { recordTypeFilter: matchingRecordTypes };
	},

	from: (val) => ({ fromDate: val ? new Date(parseInt(val, 10)) : null }),
	to: (val) => ({ toDate: val ? new Date(parseInt(val, 10)) : null }),
	fav: (val) => ({ onlySearchMyFavorites: parseInt(val, 10) === 1 ? 1 : 0 }),
	order: (val) => {
		const findAttempt = orderingOptions.find(
			(allowedOrderingOption) =>
				allowedOrderingOption.id === parseInt(val, 10)
		);
		return {
			ordering: findAttempt,
		};
	},
};

class Search extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			query: "",
			executedQuery: "",
			matterFilter: [],
			documentTypeFilter: [],
			documentStatusFilter: [],
			documentTagsFilter: [],
			usersFilter: [],
			usersLockFilter: [],
			userOperationFilter: [],
			recordTypeFilter: [],
			fromDate: null,
			toDate: null,
			onlySearchMyFavorites: false,
			ordering: orderingOptions[0],
			loadedFromUrl: false,
			runAfterNavigating: false,
		};
		this.submit = this.submit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.updateUrlParams = this.updateUrlParams.bind(this);
		this.toggleSearchFavorites = this.toggleSearchFavorites.bind(this);
	}
	static getDerivedStateFromProps(props, state) {
		if (!state.loadedFromUrl) {
			var newState = {
				...state,
				loadedFromUrl: true,
				//query: queryStringValues.q
			};
			const queryStringValues = queryString.parse(props.location.search);
			Object.keys(queryStringValues).forEach((key) => {
				const val = queryStringValues[key];
				if (queryStringMap[key]) {
					newState = {
						...newState,
						...queryStringMap[key](val, props, state),
					};
				}
			});
			return newState;
		} else {
			return null;
		}
	}
	submit(e, startPage = 1) {
		const {
			matterFilter,
			recordTypeFilter,
			fromDate,
			toDate,
			documentTypeFilter,
			documentStatusFilter,
			documentTagsFilter,
			usersFilter,
			usersLockFilter,
			userOperationFilter,
			onlySearchMyFavorites,
			ordering,
			query,
		} = this.state;
		this.props.runSearchQuery(
			query,
			{
				matterFilter: matterFilter && matterFilter.map((m) => m.id),
				recordTypeFilter:
					recordTypeFilter && recordTypeFilter.map((r) => r.value),
				fromDate: fromDate && fromDate.toISOString(),
				toDate: toDate && toDate.toISOString(),
				documentTypeFilter:
					documentTypeFilter && documentTypeFilter.map((t) => t.id),
				documentStatusFilter:
					documentStatusFilter &&
					documentStatusFilter.map((s) => s.id),
				documentTagsFilter:
					documentTagsFilter && documentTagsFilter.map((t) => t.id),
				usersFilter:
					usersFilter &&
					usersFilter.map((t) => this.props.userSubsByID[t.id]),
				usersLockFilter:
					usersLockFilter &&
					usersLockFilter.map((t) => this.props.userSubsByID[t.id]),
				userOperationFilter:
					userOperationFilter &&
					userOperationFilter.map((op) => op.value),
				onlySearchMyFavorites,
				ordering: ordering && ordering.id,
			},
			startPage
			//if startPage is not provided, as is the case when clicking the button or
			//pressing enter in the search field, the search reverts back to page 1
		);

		this.setState({ executedQuery: query }, this.updateUrlParams);
	}
	updateUrlParams() {
		const {
			matterFilter,
			recordTypeFilter,
			documentTypeFilter,
			documentStatusFilter,
			documentTagsFilter,
			usersFilter,
			usersLockFilter,
			userOperationFilter,
			fromDate,
			toDate,
			onlySearchMyFavorites,
			ordering,
			query,
		} = this.state;
		this.props.history.push({
			pathname: "/search",
			search:
				"?" +
				new URLSearchParams({
					q: query,
					m: matterFilter
						? matterFilter.map((m) => m.id).join(":")
						: "",
					type: recordTypeFilter
						? recordTypeFilter.map((m) => m.id).join(":")
						: "",
					from: fromDate ? fromDate.getTime() : "",
					to: toDate ? toDate.getTime() : "",
					docType: documentTypeFilter
						? documentTypeFilter.map((t) => t.id).join(":")
						: "",
					docStatus: documentStatusFilter
						? documentStatusFilter.map((t) => t.id).join(":")
						: "",
					docTags: documentTagsFilter
						? documentTagsFilter.map((t) => t.id).join(":")
						: "",
					users: usersFilter
						? usersFilter.map((t) => t.id).join(":")
						: "",
					lock: usersLockFilter
						? usersLockFilter.map((t) => t.id).join(":")
						: "",
					userOp: userOperationFilter
						? userOperationFilter.map((op) => op.id).join(":")
						: "",
					fav: onlySearchMyFavorites ? 1 : 0,
					order: ordering.id,
				}).toString(),
		});
	}
	componentDidMount() {
		this.props.setForcePagination(true);
		this.props.setSubheaderText("Search LexWorkplace");

		if (!this.props.hack) {
			//only used when navigating via URL, see the routes for /search in App.js for additional description
			this.props.getEverythingForSearchPage();
		}

		queryString.parse(this.props.location.search);

		if (this.state.runAfterNavigating) {
			this.submit();
		}
		this.props.setComponent(
			//this is a hack to get around redux reducer
			//not allowing functions with side effects.
			//There must be a more "correct" way to accomplish this?
			() =>
				setTimeout(() => {
					this.submit(false, this.props.currentPage);
				}, 30),
			0,
			config.searchResultsPerPage
		);
	}
	toggleSearchFavorites() {
		this.setState({
			onlySearchMyFavorites: !this.state.onlySearchMyFavorites,
		});
	}
	componentWillUnmount() {
		this.props.setForcePagination(false);
	}
	componentDidUpdate(prevProps) {
		queryString.parse(prevProps.location.search);
		const newParsed = queryString.parse(this.props.location.search);

		if (newParsed.runAfterNavigating === "1") {
			this.setState({ loadedFromUrl: false }, this.submit);
		}
	}
	handleChange(key, value) {
		var newState = { [key]: value };
		this.setState(newState);
	}
	render() {
		return (
			<div className="noDefaultMargin searchFullHeightDefault">
				{this.props.searchResultsLoading ||
				!this.props.helperDataLoaded ? (
					<LoadingBlocker />
				) : null}
				<div>
					<div
						style={{
							display: "flex",
							borderBottom: "1px solid",
							borderColor: "var(--lw-medium-grey-b)",
							padding: 28,
							paddingBottom: 16,
						}}
					>
						<div
							style={{
								flexGrow: 1,
								marginRight: 16,
							}}
						>
							<LabeledTextInput
								name="query"
								label="Search"
								defaultValue={this.state.query}
								onChange={this.handleChange}
								onEnterKey={this.submit}
								placeholder="Search LexWorkplace"
								hoverText='Enter a word or phrase to search for.  Wrap words "in quotation marks" to search for an exact phrase.'
							/>
						</div>
						<div style={{ marginTop: 20 }}>
							<ButtonWithIcon
								handler={this.submit}
								buttonText="Search"
								icon={"search"}
							/>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							aligSelf: "stretch",
							height: "100%",
						}}
					>
						<div
							style={{
								width: 423,
								paddingLeft: 32,
								paddingRight: 32,
								paddingTop: 24,
								borderRight: "1px solid",
								borderColor: "var(--lw-medium-grey-b)",
								backgroundColor: "var(--lw-light-grey-a)",
							}}
						>
							{this.props.helperDataLoaded && (
								<div>
									<div>
										<LabeledDropDownSelect
											label="Include"
											name="recordTypeFilter"
											placeholder="Everything"
											defaultValue={makeDefaultRecordTypeSelection(
												this.state.recordTypeFilter
											)}
											isClearable
											isMulti
											isAsync
											options={recordTypeOptions}
											handler={this.handleChange}
											hoverText="Specify if your search should include Documents, Email and/or Notes (found on the three tabs of each Matter)."
										/>
									</div>
									<div>
										<LabeledDropDownSelect
											label="Order By"
											name="ordering"
											defaultValue={makeDefaultOrderingSelection(
												this.state.ordering
											)}
											isAsync
											options={orderingOptions}
											handler={this.handleChange}
											hoverText="How to order search results"
										/>
									</div>
									<div
										style={{
											marginTop: 24,
											marginBottom: 16,
										}}
									>
										<div
											className="hoverPointer"
											onClick={this.toggleSearchFavorites}
										>
											<RowCheckbox
												style={{ marginRight: 8 }}
												icon={
													<CheckBoxOutlineBlankIcon fontSize="small" />
												}
												checkedIcon={
													<CheckBoxIcon fontSize="small" />
												}
												checked={
													this.state
														.onlySearchMyFavorites
														? true
														: false
												}
											/>
											Only my favorite documents
										</div>
									</div>
									<div>
										<LabeledDropDownSelect
											label="Matter"
											name="matterFilter"
											placeholder="All Matters"
											isClearable
											isMulti
											isAsync
											defaultValue={makeDefaultMatterFilterSelection(
												this.props.mattersList,
												this.state.matterFilter
											)}
											options={this.props.mattersList
												// .filter(
												// 	type => type.status === "active"
												// )
												.map((matter) => ({
													label: matter.name,
													value: matter.name,
													id: matter.id,
												}))
												.sort((a, b) => {
													if (
														a.id === -1 ||
														a.id === 0 ||
														b.id === -1 ||
														b.id === 0
													) {
														//firm docs should go at top
														return a.id < b.id;
													} else {
														// non firm docs sorted in descending
														// id order (should be most recent matter at top)
														return b.id < a.id
															? -1
															: 1;
													}
												})}
											limitNumber={50}
											overLimitDisplayNoun={"matter"}
											handler={this.handleChange}
											hoverText="Specify which matter(s) your search should include."
										/>
									</div>
									<div>
										<LabeledDropDownSelect
											label="Document Type"
											name="documentTypeFilter"
											placeholder="Any Type"
											isClearable
											isMulti
											isAsync
											isDisabled={false}
											defaultValue={makeDefaultSelection(
												this.props.documentTypeNames,
												this.state.documentTypeFilter
											)}
											options={this.props.availableDocumentTypes
												// .filter(
												// 	type => type.status === "active"
												// )
												.map((type) => ({
													label: type.name,
													value: type.name,
													id: type.id,
												}))}
											handler={this.handleChange}
											hoverText="Specify which Document Types to include. Only those document types will appear in your search results."
										/>
									</div>
									<div>
										<LabeledDropDownSelect
											label="Document Status"
											name="documentStatusFilter"
											placeholder="Any Status"
											isClearable
											isMulti
											isAsync
											isDisabled={false}
											defaultValue={makeDefaultSelection(
												this.props.documentStatusNames,
												this.state.documentStatusFilter
											)}
											options={this.props.availableDocumentStatuses
												// .filter(
												// 	type => type.status === "active"
												// )
												.map((type) => ({
													label: type.name,
													value: type.name,
													id: type.id,
												}))}
											handler={this.handleChange}
											hoverText="Specify which Document Statuses to include. Only documents with the selected statuses will appear in your search results."
										/>
									</div>
									<div>
										<LabeledDropDownSelect
											label="Document Tags"
											name="documentTagsFilter"
											placeholder="Any Tags"
											isClearable
											isMulti
											isAsync
											isDisabled={false}
											defaultValue={makeDefaultSelection(
												this.props.documentTagNames,
												this.state.documentTagsFilter
											)}
											options={this.props.availableDocumentTags
												// .filter(
												// 	type => type.status === "active"
												// )
												.map((type) => ({
													label: type.name,
													value: type.name,
													id: type.id,
												}))}
											handler={this.handleChange}
											hoverText="Specify which Document Tags to include. Only documents with these tags applied will appear in your search results."
										/>
									</div>
									<div>
										<LabeledDropDownSelect
											label="Modified By"
											name="usersFilter"
											placeholder="Any User"
											isClearable
											isMulti
											isAsync
											isDisabled={false}
											defaultValue={makeDefaultUsersFilterSelection(
												this.props.users,
												this.state.usersFilter
											)}
											options={this.props.users.map(
												(type) => ({
													label: type.displayName,
													value: type.sub,
													id: type.id,
												})
											)}
											handler={this.handleChange}
											hoverText="Only documents or matter notes which have been modified by the selected users will appear in your search results"
										/>
									</div>
									<div style={{ display: "flex" }}>
										<div style={{ marginRight: 8 }}>
											<LabeledDatePicker
												label="Modified Between"
												name="fromDate"
												defaultValue={(() => {
													return this.state.fromDate
														? new Date(
																this.state.fromDate
															)
														: null;
												})()}
												isClearable
												placeholderText="Any time in the past"
												handler={this.handleChange}
												hoverText="For Documents, show documents modified between these two dates. For Email, show email with an Email Date between these two dates."
											/>
										</div>
										<div>
											<LabeledDatePicker
												label="Until"
												name="toDate"
												defaultValue={(() => {
													return this.state.toDate
														? new Date(
																this.state.toDate
															)
														: null;
												})()}
												isClearable
												placeholderText="Present"
												handler={this.handleChange}
												hoverText="For Documents, show documents modified between these two dates. For Email, show email with an Email Date between these two dates."
											/>
										</div>
									</div>
									<div>
										<LabeledDropDownSelect
											label="Locked By"
											name="usersLockFilter"
											placeholder="Any or no Users"
											isClearable
											isMulti
											isAsync
											isDisabled={false}
											defaultValue={makeDefaultUsersFilterSelection(
												this.props.users,
												this.state.usersLockFilter
											)}
											options={this.props.users.map(
												(type) => ({
													label: type.displayName,
													value: type.sub,
													id: type.id,
												})
											)}
											handler={this.handleChange}
											hoverText="Only documents that are either 'in use' by or 'checked out' to the selected users will appear in your search results"
										/>
									</div>{" "}
								</div>
							)}
						</div>

						<div
							style={{
								marginLeft: 32,
								marginRight: 32,
								paddingTop: 8,
								width: "100%",
							}}
						>
							{this.props.searchError ? (
								<div style={{ color: "var(--lw-error)" }}>
									{this.props.customErrorMessage ||
										"The search was not successful due to an error"}
								</div>
							) : this.props.searchResultsLoading ? null : (
								<div style={{ marginBottom: 64 }}>
									<AggregationResults
										aggregations={this.props.aggregations}
										totalHits={this.props.totalHits}
										query={this.props.executedQuery}
										queryTook={this.props.queryTook}
										recordTypeMap={recordTypeMap}
										matterNames={this.props.matterNames}
										documentTypeNames={
											this.props.documentTypeNames
										}
										documentStatusNames={
											this.props.documentStatusNames
										}
										documentTagNames={
											this.props.documentTagNames
										}
										userNames={this.props.userNamesBySub}
									/>
									<SearchResults
										totalHits={this.props.totalHits}
										docs={this.props.docsResults}
										emails={this.props.emailResults}
										matterNotes={
											this.props.matterNoteResults
										}
										query={this.props.executedQuery}
										queryTook={this.props.queryTook}
										emailClicked={(prefix) =>
											this.props.getSignedUrlAndDownload(
												prefix,
												"email"
											)
										}
										documentTypeNames={
											this.props.documentTypeNames
										}
										documentStatusNames={
											this.props.documentStatusNames
										}
										documentTagNames={
											this.props.documentTagNames
										}
										userNames={this.props.userNamesBySub}
										loading={this.props.loading}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const invertArrayToObject = (arr, idKey = "id", valKey = "name") => {
	var dict = {};
	arr.forEach((x) => {
		dict[x[idKey]] = x[valKey];
	});
	return dict;
};
const mapStateToProps = (state) => {
	var matterNames = { 0: "Private Firm Docs", "-1": "Public Firm Docs" };
	matterNames = {
		...matterNames,
		...invertArrayToObject(state.search.matters),
	};
	const documentTypeNames = invertArrayToObject(
		state.search.availableDocumentTypes
	);
	const documentStatusNames = invertArrayToObject(
		state.search.availableDocumentStatuses
	);
	const documentTagNames = invertArrayToObject(
		state.search.availableDocumentTags
	);
	const userNamesBySub = invertArrayToObject(
		state.search.users,
		"sub",
		"displayName"
	);
	const userSubsByID = invertArrayToObject(state.search.users, "id", "sub");
	return {
		docsResults: state.search.results.docs,
		emailResults: state.search.results.email,
		matterNoteResults: state.search.results.matterNote,
		aggregations: state.search.results.aggregations,
		totalHits: state.search.results.totalHits,
		queryTook: state.search.queryTook,
		searchError: state.search.error,
		customErrorMessage: state.search.customErrorMessage,
		executedQuery: state.search.executedQuery,
		helperDataLoaded: state.search.helperDataLoaded,
		mattersList: state.search.matters,
		availableDocumentStatuses: state.search.availableDocumentStatuses,
		availableDocumentTypes: state.search.availableDocumentTypes,
		availableDocumentTags: state.search.availableDocumentTags,
		users: state.search.users,
		matterNames,
		documentTypeNames,
		documentStatusNames,
		documentTagNames,
		userNamesBySub: userNamesBySub,
		userSubsByID: userSubsByID,
		currentPage: state.pagination.currentPage,
		searchResultsLoading: state.search.loading,
	};
};
export default connect(mapStateToProps, {
	runSearchQuery,
	getEverythingForSearchPage,
	setSubheaderText,
	getSignedUrlAndDownload,
	setForcePagination,
	setComponent,
})(Search);
