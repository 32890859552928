import {
	SEARCH_RESULTS_REQUEST_NOGLOBAL,
	SEARCH_RESULTS_SUCCESS_NOGLOBAL,
	SEARCH_RESULTS_FAILURE_NOGLOBAL,
	SEARCH_HELPER_SUCCESS_NOGLOBAL,
} from "../actions/types";
//
const initialState = {
	query: "",
	executedQuery: "",
	results: { docs: [], email: [], matterNote: [], aggregations: {} },
	queryTook: 0,
	error: false,
	customErrorMessage: null,
	helperDataLoaded: false,
	matters: [],
	availableDocumentStatuses: [],
	availableDocumentTypes: [],
	availableDocumentTags: [],
	users: [],
	loading: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SEARCH_RESULTS_REQUEST_NOGLOBAL:
			return {
				...state,
				loading: true,
			};
		case SEARCH_RESULTS_SUCCESS_NOGLOBAL:
			return {
				...state,
				results: action.payload.results,
				executedQuery: action.payload.executedQuery,
				queryTook:
					Math.floor(parseInt(action.payload.queryTook, 10) / 10) /
					100,
				error: false,
				loading: false,
			};
		case SEARCH_RESULTS_FAILURE_NOGLOBAL: {
			let customErrorMessage;
			switch (action.payload.lxwErrorCode) {
				case 8202:
					{
						customErrorMessage =
							"Your search index has not been configured yet.  Please check back again later";
					}
					break;
				default:
			}
			return {
				...state,
				results: [],
				executedQuery: action.payload.executedQuery,
				queryTook:
					Math.floor(parseInt(action.payload.queryTook, 10) / 10) /
					100,
				error: true,
				customErrorMessage,
				loading: false,
			};
		}
		case SEARCH_HELPER_SUCCESS_NOGLOBAL:
			return {
				...state,
				...action.payload,
				helperDataLoaded: true,
			};
		default:
			return state;
	}
}
