import React, { Component } from "react";

import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";

// options item format: { label: "Display Label", value: "value", id: ID }

const addFooterIfNecessary = (
	options,
	moreResultsText = "Please modify your filter to see fewer results",
	maxResultsToShowInDropdown = 50,
	// eg: an async search, the server found 203 results that match
	// the user's query but only returns a page of 50 at a time
	totalResults = options.length
) => {
	let optionsToShow = options;

	if (options.length > maxResultsToShowInDropdown) {
		optionsToShow = options.slice(0, maxResultsToShowInDropdown);
	}
	if (totalResults > optionsToShow.length) {
		optionsToShow.push({
			label: `...and ${
				totalResults - optionsToShow.length
			} more.  ${moreResultsText}`,
			value: "",
			isDisabled: true,
		});
	}
	return optionsToShow;
};
export class AsyncSelectComponent extends Component {
	state = { inputValue: "", allOptions: this.props.allOptions };
	handleInputChange = (inputValue) => {
		this.setState({ inputValue });
		this.props.onInputChange && this.props.onInputChange(inputValue);
		return inputValue;
	};
	loadOptionsAndFilterSync = (inputValue) => {
		return this.filterOptions(inputValue, this.props.options);
	};
	filterOptions = (inputValue, options) => {
		var matches = options.filter((option) =>
			option.label.toLowerCase().includes(inputValue.toLowerCase())
		);
		return addFooterIfNecessary(
			matches,
			this.props.moreResultsText,
			this.props.limitNumber
		);
	};
	makeDefaultOptions = () => {
		return this.filterOptions("", this.props.options);
	};

	loadOptions = (inputValue) => {
		if (this.props.loadOptions) {
			return this.props.loadOptions(inputValue).then((options) => {
				return addFooterIfNecessary(
					options,
					this.props.moreResultsText,
					this.props.limitNumber,
					this.props.totalResults
				);
			});
		} else {
			return Promise.resolve(this.loadOptionsAndFilterSync(inputValue));
		}
	};

	render() {
		if (this.props.isCreatable) {
			return (
				<div>
					<AsyncCreatableSelect
						{...this.props}
						cacheOptions={this.props.disableCache ? false : true}
						loadOptions={this.loadOptions}
						onInputChange={this.handleInputChange}
						defaultOptions={
							this.props.defaultOptions ||
							this.makeDefaultOptions()
						}
					/>
				</div>
			);
		} else {
			return (
				<div>
					<AsyncSelect
						{...this.props}
						cacheOptions={this.props.disableCache ? false : true}
						loadOptions={this.loadOptions}
						onInputChange={this.handleInputChange}
						defaultOptions={
							this.props.defaultOptions ||
							this.makeDefaultOptions()
						}
					/>
				</div>
			);
		}
	}
}
