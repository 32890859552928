import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	getClientTagsOperation,
	createNewClient,
	updateClient,
	fetchClientDetail,
	getEverythingForNewClient,
	getEverythingForEditClient,
	unmountNewEditClient,
} from "../actions/clientActions";
import { clientStatusOptions, clientStatusLookup } from "../SelectableOptions";
import { LabeledTextInput } from "./TextInput";
import { LabeledDropDownSelect } from "./DropDownSelect";
import TwoColumnWrapper from "./TwoColumnWrapper";

const tagLabelMap = (tag) => ({
	value: tag.name,
	label: tag.name,
});
const propTagsMap = (tag) => ({
	label: tag.tagName,
	value: tag.tagName,
});
const valueLabelMap = (x) => ({ value: x.id, label: x.displayName });

const initialState = {
	newClientName: "",
	newClientStatus: { value: "active", label: "Active" },
	primaryAttorneys: [],
	clientTags: [],
};

class NewClient extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newClientName: "",
			newClientStatus: { value: "active", label: "Active" },
			primaryAttorneys: [],
			clientTags: [],
			loading: true,
		};
		this.handleChange = this.handleChange.bind(this);
	}
	static getDerivedStateFromProps(props, state) {
		if (state.loading && !props.loading) {
			const newState = props.editMode
				? {
						loading: false,
						newClientName: props.clientDetails.clientName,
						newClientStatus:
							clientStatusOptions[
								props.editMode
									? clientStatusLookup[
											props.clientDetails.clientStatus
										]
									: 0
							],
						clientTags: props.clientDetails.tags
							? props.clientDetails.tags.map(propTagsMap)
							: [],
						primaryAttorneys: props.clientDetails.primaryAttorneys
							? props.clientDetails.primaryAttorneys.map(
									valueLabelMap
								)
							: [],
					}
				: { ...initialState, loading: false };
			return newState;
		} else {
			return null;
		}
	}
	componentDidMount() {
		if (this.props.editMode) {
			this.props.getEverythingForEditClient(
				this.props.match.params.id,
				this.props.history
			);
		} else {
			this.props.getEverythingForNewClient();
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.editMode !== this.props.editMode) {
			if (this.props.editMode) {
				this.props.getEverythingForEditClient(
					this.props.match.params.id,
					this.props.history
				);
			} else {
				this.props.getEverythingForNewClient();
			}
		}
	}
	componentWillUnmount() {
		this.props.unmountNewEditClient();
	}
	handleChange(key, value) {
		this.setState({ [key]: value });
	}
	submit() {
		if (this.props.editMode) {
			this.props.updateClient(this.props.match.params.id, this.state);
		} else {
			this.props.createNewClient(this.state);
		}
		setTimeout(() => {
			this.props.history.push("/clients/");
		}, 1000);
	}
	render() {
		if (this.state.loading) {
			return (
				<div>
					<h1>
						Loading<span className="Loading">...</span>
					</h1>
				</div>
			);
		} else {
			return (
				<TwoColumnWrapper
					headingText="Client Information"
					iconName="user"
					submit={this.submit.bind(this)}
					canSubmit={this.state.newClientName.trim() !== ""}
					submitButtonText={
						this.props.editMode ? "Update Client" : "Create Client"
					}
					leftChildren={
						<div>
							<LabeledTextInput
								label="Client Name*"
								name="newClientName"
								placeholder="New Client Name"
								defaultValue={
									this.props.editMode
										? this.props.clientDetails.clientName
										: null
								}
								onChange={this.handleChange}
							/>
							<LabeledDropDownSelect
								label="Client Status"
								options={clientStatusOptions}
								defaultValue={
									clientStatusOptions[
										this.props.editMode
											? clientStatusLookup[
													this.props.clientDetails
														.clientStatus
												]
											: 0
									]
								}
								name="newClientStatus"
								handler={this.handleChange}
							/>
						</div>
					}
					rightChildren={
						<div>
							<LabeledDropDownSelect
								label="Primary Attorney(s)"
								isMulti
								name="primaryAttorneys"
								defaultValue={
									this.props.editMode &&
									this.props.clientDetails.primaryAttorneys
										? this.props.clientDetails.primaryAttorneys.map(
												valueLabelMap
											)
										: []
								}
								isAsync
								options={this.props.availablePrimaryAttorneys.map(
									valueLabelMap
								)}
								handler={this.handleChange}
							/>
							<LabeledDropDownSelect
								label="Client Tag(s)"
								isMulti
								name="clientTags"
								isCreatable
								isAsync
								defaultValue={
									this.props.editMode &&
									this.props.clientDetails.tags
										? this.props.clientDetails.tags.map(
												propTagsMap
											)
										: []
								}
								options={this.props.availableClientTags
									.filter((tag) => tag.status === "active")
									.map(tagLabelMap)}
								handler={this.handleChange}
							/>
						</div>
					}
				/>
			);
		}
	}
}
const mapStateToProps = (state) => {
	return {
		availableClientTags: state.client.availableClientTags,
		availablePrimaryAttorneys: state.client.availablePrimaryAttorneys,
		clientDetails: state.client.clientDetails,
		loading: state.client.newEditClientLoading,
	};
};
NewClient.propTypes = {
	getClientTagsOperation: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
	getClientTagsOperation,
	createNewClient,
	updateClient,
	fetchClientDetail,
	getEverythingForNewClient,
	getEverythingForEditClient,
	unmountNewEditClient,
})(NewClient);
