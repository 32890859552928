/*
	Handles New and Edit matter functionality.  props.editMode determines which.
*/

import React from "react";
import { LabeledDropDownSelect } from "./DropDownSelect";
import {
	allowListDropdownMap,
	findAdminGroups,
	groupListDropdownMap,
	matterPermissionGlobalSettingLookup,
	matterPermissionGlobalSettingOptions,
} from "../utilities/utilities";

class EditMatterPermissions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userAllowList: props.matterPermissions.userAllowList.map((u) =>
				allowListDropdownMap(u, this.props.userDisplayNamesBySub)
			),
			groupAllowList: props.matterPermissions.groupAllowList.map((g) =>
				groupListDropdownMap(g, this.props.availableGroupNamesByID)
			),
			matterPermissionGlobalSetting:
				matterPermissionGlobalSettingOptions[
					matterPermissionGlobalSettingLookup[
						props.matterPermissions.matterPermissionGlobalSetting
					]
				],
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(key, value) {
		const changeCallback = (setToAllUsers) => {
			this.props.onChange({
				matterPermissionGlobalSetting:
					this.state.matterPermissionGlobalSetting.value,
				userAllowList:
					this.state.userAllowList && !setToAllUsers
						? this.state.userAllowList.map((u) => ({
								userSub: u.value,
								type: "ALLOW_READ_WRITE",
							}))
						: [],
				groupAllowList:
					this.state.groupAllowList && !setToAllUsers
						? this.state.groupAllowList.map((g) => ({
								groupID: g.value,
								type: "ALLOW_READ_WRITE",
							}))
						: [],
			});
		};
		this.setState({ [key]: value }, () => {
			//send the simplified state up to NewMatter component
			const setToAllUsers =
				this.state.matterPermissionGlobalSetting.value === "allUsers";

			if (!setToAllUsers && this.state.groupAllowList.length === 0) {
				this.setState(
					{
						groupAllowList: findAdminGroups(
							this.props.availableGroups
						),
					},
					() => changeCallback(setToAllUsers)
				);
			} else {
				changeCallback(setToAllUsers);
			}
		});
	}

	render() {
		return (
			<div>
				<div style={{ width: 300 }}>
					<LabeledDropDownSelect
						label="This Matter is Accessible to"
						options={matterPermissionGlobalSettingOptions}
						defaultValue={this.state.matterPermissionGlobalSetting}
						name="matterPermissionGlobalSetting"
						handler={this.handleChange}
					/>
				</div>
				{this.state.matterPermissionGlobalSetting.value ===
				"specifiedUsers" ? (
					<div>
						<div>
							<LabeledDropDownSelect
								label="Users with Access to this Matter"
								isMulti
								name="userAllowList"
								isAsync
								defaultValue={this.state.userAllowList}
								options={this.props.availableUsers}
								handler={this.handleChange}
							/>
						</div>
						<div>
							<LabeledDropDownSelect
								label="Groups with Access to this Matter"
								isMulti
								name="groupAllowList"
								isAsync
								isClearable
								value={this.state.groupAllowList}
								defaultValue={this.state.groupAllowList}
								options={this.props.availableGroups}
								handler={this.handleChange}
							/>
						</div>
					</div>
				) : null}
			</div>
		);
	}
}
export default EditMatterPermissions;
