import React, { Component } from "react";
import { connect } from "react-redux";
import { LabeledDropDownSelect } from "./DropDownSelect";
import {
	allowListDropdownMap,
	findAdminGroups,
	groupListDropdownMap,
	matterPermissionGlobalSettingLookup,
	matterPermissionGlobalSettingOptions,
	numberWithCommas,
	makeDebouncedFunction,
} from "../utilities/utilities";
import Warning from "@material-ui/icons/Warning";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import { LabeledTextInput } from "./TextInput";
import { CircularProgress } from "@material-ui/core";

class BulkMatterPermissionsUnconnected extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userAllowList: props.userAllowList
				? props.userAllowList.map((u) =>
						allowListDropdownMap(
							u,
							this.props.userDisplayNamesBySub
						)
					)
				: [],
			groupAllowList: props.groupAllowList
				? props.groupAllowList.map((g) =>
						groupListDropdownMap(
							g,
							this.props.availableGroupNamesByID
						)
					)
				: [],
			matterPermissionGlobalSetting:
				matterPermissionGlobalSettingOptions[
					matterPermissionGlobalSettingLookup["allUsers"]
				],
			confirmTextBox: "",
		};
		this.handleChangeHere = makeDebouncedFunction(
			this.handleChangeHere.bind(this),
			300
		);
	}

	handleChangeHere(key, value) {
		const changeCallback = (setToAllUsers) => {
			// send the changes back to the parent component (in this case EditDialog)
			// expects each one to be a separate call
			// so I moved all info into a single object
			this.props.onChange("matterPermissionsOutputObject", {
				matterPermissionGlobalSetting:
					this.state.matterPermissionGlobalSetting.value,
				userAllowList:
					this.state.userAllowList && !setToAllUsers
						? this.state.userAllowList.map((u) => ({
								userSub: u.value,
								type: "ALLOW_READ_WRITE",
							}))
						: [],
				groupAllowList:
					this.state.groupAllowList && !setToAllUsers
						? this.state.groupAllowList.map((g) => ({
								groupID: g.value,
								type: "ALLOW_READ_WRITE",
							}))
						: [],
				matterIDsToApplyPermissionsTo:
					this.props.matterIDsToApplyPermissionsTo,
				ignoreMattersThatAlreadyHavePermissions:
					this.state.ignoreMattersThatAlreadyHavePermissions,
				confirmed:
					this.state.confirmTextBox &&
					this.state.confirmTextBox.toLowerCase() === "confirm",
				allMatchingMatterIDsWithPermissions:
					this.props.allMatchingMatterIDsWithPermissions,
				numMatterPermissionsToBeUpdated: this.state
					.ignoreMattersThatAlreadyHavePermissions
					? this.props.matterIDsToApplyPermissionsTo.length -
						this.props.allMatchingMatterIDsWithPermissions.length
					: this.props.matterIDsToApplyPermissionsTo.length,
			});
		};
		this.setState({ [key]: value }, () => {
			//send the simplified state up to NewMatter component
			const setToAllUsers =
				this.state.matterPermissionGlobalSetting.value === "allUsers";

			if (!setToAllUsers && this.state.groupAllowList.length === 0) {
				this.setState(
					{
						groupAllowList: findAdminGroups(
							this.props.availableGroups
						),
					},
					() => changeCallback(setToAllUsers)
				);
			} else {
				changeCallback(setToAllUsers);
			}
		});
	}

	render() {
		const {
			allMatchingMatterIDsWithPermissions,
			matterIDsToApplyPermissionsTo,
			hasValidActiveFilter,
			numFilteredMattersString,
			numAllMattersString,
			clientName,
		} = this.props;

		const numMatterPermissionsToBeUpdated = this.state
			.ignoreMattersThatAlreadyHavePermissions
			? `${numberWithCommas(matterIDsToApplyPermissionsTo.length - allMatchingMatterIDsWithPermissions.length)}`
			: `${numberWithCommas(matterIDsToApplyPermissionsTo.length)}`;

		let matterPermissionsMessage = "";

		if (hasValidActiveFilter) {
			matterPermissionsMessage = `Edit permissions for visible matters${clientName ? ` for ${clientName}` : ""}. You are about to change the permissions for ${numFilteredMattersString} of ${numAllMattersString} total matters (all matters included in the current view)${clientName ? ` for ${clientName}` : ""}.  This will affect all users with access to these matters.`;
		} else {
			matterPermissionsMessage = `Edit permissions for ALL matters${clientName ? ` for ${clientName}` : ""}. You are about to change the permissions for ALL ${numAllMattersString} matters ${clientName ? ` for ${clientName}` : ""}.  This will affect all users with access to these matters.`;
		}

		// this loading prop is in the bulkMatterPermissionsObject that is passed into openGlobalDialog
		if (this.props.loading) {
			return <CircularProgress />;
		}
		return (
			<div>
				<div style={{ marginBottom: 10 }}>
					{matterPermissionsMessage}
				</div>
				{allMatchingMatterIDsWithPermissions.length > 0 ? (
					<div>
						<div
							style={{
								borderRadius: 5,
								backgroundColor: "var(--lw-gold)",
								display: "flex",
								alignItems: "center",
							}}
						>
							<Warning
								style={{
									color: "white",
									margin: 10,
								}}
							/>{" "}
							Explicit Permissions for{" "}
							{allMatchingMatterIDsWithPermissions.length}{" "}
							matter(s) will be{" "}
							{this.state.ignoreMattersThatAlreadyHavePermissions
								? "unchanged"
								: "overwritten"}
							.
						</div>
						<div style={{ marginLeft: "-15px", marginTop: "5px" }}>
							<Checkbox
								color="primary"
								style={{ left: 4 }}
								icon={
									<CheckBoxOutlineBlankIcon fontSize="small" />
								}
								checkedIcon={<CheckBoxIcon fontSize="small" />}
								onChange={(event) => {
									if (event.target.checked) {
										this.handleChangeHere(
											"ignoreMattersThatAlreadyHavePermissions",
											true
										);
									} else {
										this.handleChangeHere(
											"ignoreMattersThatAlreadyHavePermissions",
											false
										);
									}
								}}
							/>
							Do not update permissions for these matters
						</div>
					</div>
				) : null}
				<div>
					<div style={{ width: 300 }}>
						<LabeledDropDownSelect
							label="Make These Matters Accessible to"
							options={matterPermissionGlobalSettingOptions}
							defaultValue={
								this.state.matterPermissionGlobalSetting
							}
							name="matterPermissionGlobalSetting"
							handler={this.handleChangeHere}
						/>
					</div>
					{this.state.matterPermissionGlobalSetting.value ===
					"specifiedUsers" ? (
						<div>
							<div>
								<LabeledDropDownSelect
									label="Users with Access to these Matters"
									isMulti
									name="userAllowList"
									isAsync
									defaultValue={this.state.userAllowList}
									options={this.props.availableUsers}
									handler={this.handleChangeHere}
								/>
							</div>
							<div>
								<LabeledDropDownSelect
									label="Groups with Access to these Matters"
									isMulti
									name="groupAllowList"
									isAsync
									isClearable
									value={this.state.groupAllowList}
									defaultValue={this.state.groupAllowList}
									options={this.props.availableGroups}
									handler={this.handleChangeHere}
								/>
							</div>
						</div>
					) : null}
					<div style={{ marginBottom: 10 }}>
						{`Are you sure you want to continue? This will update permissions for ${numMatterPermissionsToBeUpdated} matters. Please type "confirm" in the box below to confirm and proceed.`}
					</div>
					<LabeledTextInput
						label=""
						name="confirmTextBox"
						defaultValue={this.state.confirmTextBox}
						placeholder="Type confirm here"
						onChange={this.handleChangeHere}
						autocomplete="off"
					/>
				</div>
			</div>
		);
	}
}

const BulkMatterPermissions = connect()(BulkMatterPermissionsUnconnected);

export default BulkMatterPermissions;
