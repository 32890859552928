import { SET_LXW_LOCALSTORAGE_VALUE } from "./types";

function toggleValue(key) {
	return function (dispatch, getState) {
		const { browserLocalstorage } = getState();
		const newValue = !browserLocalstorage[key];
		setLocalstorageValue(key, newValue)(dispatch);
	};
}

export function setLocalstorageValue(key, value) {
	return function (dispatch) {
		dispatch({ type: SET_LXW_LOCALSTORAGE_VALUE, payload: { key, value } });
	};
}
export function toggleSidenav() {
	return toggleValue("sidenavExpanded");
}
export function toggleMatterInfo() {
	return toggleValue("matterInfoHidden");
}
export function updateWhatsNewDropdownLastHashViewed(currentWhatsNewItemsHash) {
	return setLocalstorageValue(
		"whatsNewDropdownLastHashViewed",
		currentWhatsNewItemsHash
	);
}
