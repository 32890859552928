import React, { Component } from "react";
import { connect } from "react-redux";
import {
	setScreenSize,
	setResizeElementSize,
	setHeaderOverflow,
	setScrollbarWidth,
} from "../actions/globalScreenSizeActions";

class GlobalScreenSize extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			tableHeight: 400,
			windowHeight: 500,
		};
	}
	render() {
		return <div style={{ display: "none" }} />;
	}
	componentDidMount() {
		window.setInterval(() => {
			const windowWidth = window.innerWidth;
			const windowHeight = window.innerHeight;
			const container = document.querySelectorAll(
				".autoResizeTableContainer"
			);

			if (container[0]) {
				// The React Table component is not designed to have a scollbar.
				// Thus, then the scrollbar exists the columns and headings do not line up
				// This checks the DOM elements to determine if the scrollbar is present,
				// and sets a global variable so the table headers & rows are aligned
				var tableEl = container[0].querySelector("div .ReactTable");

				var bodyEl = tableEl
					? tableEl.querySelector(".rt-table .rt-tbody")
					: null;

				if (tableEl && bodyEl) {
					const thisScrollbarWidth =
						tableEl.clientWidth - bodyEl.clientWidth;

					if (thisScrollbarWidth !== this.props.scrollbarWidth) {
						this.props.setScrollbarWidth(
							tableEl.clientWidth - bodyEl.clientWidth
						);
					}
				}
				if (this.props.allowUnderHeaderOverflow) {
					this.props.setHeaderOverflow(false);
				}
				if (this.props.resizeElementSize) {
					const el = container[0];
					if (
						el.clientHeight !==
							this.props.resizeElementSize.height ||
						el.clientWidth !== this.props.resizeElementSize.width
					) {
						this.props.setResizeElementSize({
							width: el.clientWidth,
							height: el.clientHeight,
						});
					}
				}
			} else {
				if (!this.props.allowUnderHeaderOverflow) {
					this.props.setHeaderOverflow(true);
				}
			}
			if (
				this.props.screenSize.w !== windowWidth ||
				this.props.screenSize.h !== windowHeight
			) {
				this.props.setScreenSize({ w: windowWidth, h: windowHeight });
			}
		}, 500);
	}
}
const mapStateToProps = (state) => ({
	screenSize: state.global.size,
	resizeElementID: state.global.resizeElementID,
	resizeElementSize: state.global.resizeElementSize,
	scrollbarWidth: state.global.scrollbarWidth,
	allowUnderHeaderOverflow: state.global.allowUnderHeaderOverflow,
});
export default connect(mapStateToProps, {
	setScreenSize,
	setResizeElementSize,
	setHeaderOverflow,
	setScrollbarWidth,
})(GlobalScreenSize);
