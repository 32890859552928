import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { toggleColumnVisibility } from "../../actions/customColumnActions";

import { DropDownCheckboxSelector } from "../DropDownCheckboxSelector";

import { FilterableTable } from "./SortableTable";

import { resetTableColumnWidths } from "../../actions/tableSortingActions";
import EmptyPaginationComponent from "../EmptyPagination";
import { setTableTabFilter } from "../../actions/tableSortingActions";
import { TabLookingRadioButtonsWithIcon } from "../TabLookingRadioButtons";
import { setFilteredCount } from "../../actions/globalPaginationActions";
import ButtonLink from "../ButtonLink";

/*
	ReactTable wrapper with selector for custom columns, and optional "tabs" interface
	Includes controlled filter state for the react-table

*/

class CustomizableTable extends Component {
	editableKeyToFocus = createRef(null);
	cursorRef = createRef(0);
	constructor(props, context) {
		super(props, context);

		this.state = {
			activeFilters: [],
		};

		this.columnCheckboxSelected = this.columnCheckboxSelected.bind(this);
		this.switchedTab = this.switchedTab.bind(this);
		this.getLeftSideComponent = this.getLeftSideComponent.bind(this);
		this.getTabFilteredData = this.getTabFilteredData.bind(this);
		this.getClearFilterButton = this.getClearFilterButton.bind(this);
		this.filtersChangedCallback = this.filtersChangedCallback.bind(this);
	}
	columnCheckboxSelected(item) {
		this.props.toggleColumnVisibility(this.props.tableUniqueID, item);
	}
	switchedTab(newTabTitle) {
		this.props.setTableTabFilter(this.props.tableUniqueID, newTabTitle);
		this.props.tabFilterChanged && this.props.tabFilterChanged(newTabTitle);
	}
	getLeftSideComponent() {
		if (this.props.tabsFilterMap) {
			return (
				<TabLookingRadioButtonsWithIcon
					icon={this.props.leftSideIcon || "briefcase"}
					columns={Object.keys(this.props.tabsFilterMap)}
					switchedTab={this.switchedTab}
					activeTabName={this.props.tabFilterName}
					tabDescriptions={this.props.tabDescriptions}
				/>
			);
		}
		return this.props.leftSideFilterSpace;
	}

	filtersChangedCallback(newFilters) {
		if (this.props.onFilteredChange) {
			this.props.onFilteredChange(newFilters);
		}
	}

	getClearFilterButton() {
		return this.state.activeFilters.length ? (
			<div
				style={{
					paddingTop: "5px",
					lineHeight: "31px",
					marginRight: "16px",
				}}
			>
				<ButtonLink
					onClick={() =>
						this.setState({ activeFilters: [] }, () => {
							this.props.setFilteredCount(-1);
							this.filtersChangedCallback(null);
						})
					}
				>
					✕ Clear Filters
				</ButtonLink>
			</div>
		) : null;
	}
	getTabFilteredData() {
		if (this.props.tabsFilterMap) {
			return this.props.data.filter(
				this.props.tabsFilterMap[this.props.tabFilterName]
			);
		} else {
			return this.props.data;
		}
	}

	render() {
		return (
			<div
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<div style={{ display: "flex", marginTop: 8 }}>
					{this.getLeftSideComponent()}

					<div
						style={{
							marginLeft: "auto",
							marginTop: 0,
							marginBottom: 8,
							marginRight: 16,
							display: "flex",
							height: "min-content", // this prevents excess space below the buttons so the dropdowns always stay directly below the buttons
						}}
						className="buttonGroup"
					>
						{this.getClearFilterButton()}
						{this.props.actionButtons}
						<DropDownCheckboxSelector
							title="Columns"
							iconName="columns"
							pullRight
							nonCheckboxItems={[
								{
									text: "Reset Column Widths",
									onSelect: () =>
										this.props.resetTableColumnWidths(
											this.props.tableUniqueID
										),
								},
							]}
							checkboxItems={this.props.columns
								.filter((v) => v.accessor !== "actions")
								.filter((v) => !v.unhidable)
								.filter((v) => {
									if (this.props.hiddenColumns) {
										return !this.props.hiddenColumns.includes(
											v.accessor
										);
									} else {
										return true;
									}
								})
								.map((v) => ({
									text: v.Header,
									accessor: v.accessor,
									onSelect: this.columnCheckboxSelected,
									selected:
										this.props.selectedColumns[v.accessor],
								}))}
						/>
					</div>
				</div>
				<div className="autoResizeTableContainer">
					<FilterableTable
						{...this.props}
						data={this.getTabFilteredData()}
						filtered={this.state.activeFilters}
						onFilteredChange={(newFiltering, column, value) => {
							this.setState(
								{ activeFilters: [...newFiltering] },
								() => {
									this.filtersChangedCallback(newFiltering);
								}
							);
						}}
						/*
							In react-table v6 if the filter is controlled
							(via the 'filtered' prop), then changing the filter value causes the table
							and all its filterComponents to re-render and the focus and cursor position
							is reset.  This custom FilterComponent re-implements the desired behavior,
							which is what's seen with a react-table component with an un-controlled "filtered" prop
						*/
						FilterComponent={({ filter, onChange, column }) => (
							<input
								type="text"
								style={{
									width: "100%",
								}}
								autoFocus={
									column.id ===
									this.editableKeyToFocus.current
								}
								onFocus={(e) => {
									e.currentTarget.setSelectionRange(
										this.cursorRef.current,
										this.cursorRef.current
									);
								}}
								placeholder={column.Placeholder}
								value={filter ? filter.value : ""}
								onChange={(event) => {
									this.editableKeyToFocus.current = column.id;
									this.cursorRef.current =
										event.target.selectionStart;
									onChange(event.target.value);
								}}
							/>
						)}
						PaginationComponent={EmptyPaginationComponent}
						style={{
							height: this.props.loading
								? null
								: this.props.containerHeight,
						}}
						columns={this.props.columns.filter((v) => {
							if (this.props.hiddenColumns) {
								return !this.props.hiddenColumns.includes(
									v.accessor
								);
							} else {
								return true;
							}
						})}
						columnVisibility={{
							...this.props.selectedColumns,
							actions: true,
						}}
						minRows={0} // hides the empty "padding" rows that appear after filtering
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const tabFilterName =
		ownProps.tabFilterOverride ||
		(state.tableSorting[ownProps.tableUniqueID] &&
			state.tableSorting[ownProps.tableUniqueID].tabFilter) ||
		ownProps.defaultTabFilterValue;

	return {
		selectedColumns: state.customColumns[ownProps.tableUniqueID],
		containerHeight: state.global.resizeElementSize.height,
		tabFilterName,
	};
};

export default connect(mapStateToProps, {
	toggleColumnVisibility,
	setTableTabFilter,
	resetTableColumnWidths,
	setFilteredCount,
})(CustomizableTable);
