import React, { Component } from "react";
import { connect } from "react-redux";
import Snackbar from "./Snackbar";
import { closeSnackbar } from "../actions/globalSnackbarActions";

class GlobalSnackbar extends Component {
	render() {
		return (
			<Snackbar
				onClose={this.props.closeSnackbar}
				open={this.props.open}
				message={this.props.message}
				variant={this.props.variant}
				autoHideDuration={this.props.autoHideDuration}
				loadingSpinnerVisible={this.props.loadingSpinnerVisible}
				allowClose={this.props.allowClose}
				disableWindowBlurListener={this.props.disableWindowBlurListener}
			/>
		);
	}
}
const mapStateToProps = (state) => ({
	open: state.snackbar.open,
	message: state.snackbar.message,
	variant: state.snackbar.variant,
	autoHideDuration: state.snackbar.autoHideDuration,
	loadingSpinnerVisible: state.snackbar.loadingSpinnerVisible,
	allowClose: state.snackbar.allowClose,
	disableWindowBlurListener: state.snackbar.disableWindowBlurListener,
});
export default connect(mapStateToProps, { closeSnackbar })(GlobalSnackbar);
