import React from "react";

export const oneToTen = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const zeroToNine = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
export const oneOrZero = [0, 1];
export const digits = "0123456789";
export const lowercase = "abcdefghijklmnopqrstuvwxyz";
export const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const b62 =
	"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const b64 =
	"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_";

export function sequence(len = 10) {
	var out = [];
	for (var i = 0; i < len; i++) {
		out.push(i);
	}
	return out;
}
export function randomString(
	str = letters,
	len = 8,
	prefix = "",
	postfix = ""
) {
	const lhs = sequence(len).reduce(
		(accum) => accum + str[Math.floor(Math.random() * str.length)],
		prefix
	);
	return lhs + postfix;
}
export function randomFromArray(arr = oneToTen) {
	return arr[Math.floor(Math.random() * arr.length)];
}
export function sampleFromArray(arr = oneToTen, num = 1) {
	var out = [];
	for (var i = 0; i < num; i++) {
		out.push(randomFromArray(arr));
	}
	return out;
}
export function humanFileSize(bytes, si) {
	var thresh = si ? 1000 : 1024;
	if (Math.abs(bytes) < thresh) {
		return bytes + " B";
	}
	var units = si
		? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
		: ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
	var u = -1;
	do {
		bytes /= thresh;
		++u;
	} while (Math.abs(bytes) >= thresh && u < units.length - 1);
	return bytes.toFixed(1) + " " + units[u];
}
export const iconMap = (row) => {
	const defaultIcon = { icon: "file", color: "grey" };

	if (row.original.type === "NoIcon") {
		return { icon: "", color: "" };
	} else if (row.original.type === "Folder") {
		return { icon: "folder", color: "var(--lw-folder-icon-yellow)" };
	} else if (row.original.type === "Email") {
		return { icon: "envelope", color: "grey" };
	} else if (row.original.type === "Note") {
		return { icon: "pencil-alt", color: "grey" };
	} else if (row.original.type === "File") {
		const { extensionLowerCase } = splitFilename(row.original.filename);

		const map = {
			pdf: { icon: "file-pdf", color: "red" },

			doc: { icon: "file-word", color: "#335290" },
			docx: { icon: "file-word", color: "#335290" },

			xls: { icon: "file-excel", color: "#3b714b" },
			xlsx: { icon: "file-excel", color: "#3b714b" },

			ppt: { icon: "file-powerpoint", color: "red" },
			pptx: { icon: "file-powerpoint", color: "red" },

			mov: { icon: "file-video", color: "red" },
			wmv: { icon: "file-video", color: "red" },
			mp4: { icon: "file-video", color: "red" },
			avi: { icon: "file-video", color: "red" },
			webm: { icon: "file-video", color: "red" },
			avchd: { icon: "file-video", color: "red" },

			jpg: { icon: "file-image", color: "green" },
			jpeg: { icon: "file-image", color: "green" },
			png: { icon: "file-image", color: "green" },
			bmp: { icon: "file-image", color: "green" },
			tif: { icon: "file-image", color: "green" },
			tiff: { icon: "file-image", color: "green" },
			webp: { icon: "file-image", color: "green" },
			svg: { icon: "file-image", color: "green" },
			gif: { icon: "file-image", color: "green" },

			wav: { icon: "file-audio", color: "grey" },
			mp3: { icon: "file-audio", color: "grey" },
			ogg: { icon: "file-audio", color: "grey" },
			flac: { icon: "file-audio", color: "grey" },
			m4a: { icon: "file-audio", color: "grey" },
			mid: { icon: "file-audio", color: "grey" },
			midi: { icon: "file-audio", color: "grey" },

			eml: { icon: "envelope", color: "grey" },
			msg: { icon: "envelope", color: "grey" },

			zip: { icon: "file-archive", color: "grey" },

			csv: { icon: "file-csv", color: "grey" },

			js: { icon: "file-code", color: "grey" },
			html: { icon: "file-code", color: "grey" },
			json: { icon: "file-code", color: "grey" },
			css: { icon: "file-code", color: "grey" },
			htm: { icon: "file-code", color: "grey" },
			py: { icon: "file-code", color: "grey" },
			c: { icon: "file-code", color: "grey" },
			h: { icon: "file-code", color: "grey" },
		};
		return map[extensionLowerCase] || defaultIcon;
	} else {
		return defaultIcon;
	}
};
export const shortDateFormat = (val) => {
	var d = new Date(val);
	return d.toLocaleDateString("en-US");
};
export const shortDateFormatOffset = (val) => {
	var d = new Date(val);
	var offset = d.getTimezoneOffset() * 60000;
	var absoluteDate = new Date(d.getTime() + offset);
	return absoluteDate.toLocaleDateString("en-US");
};
window.shortDateFormat = shortDateFormat;
export const mediumDateFormat = (val) => {
	var options = {
		hour: "numeric",
		minute: "numeric",
	};
	var date = new Date(val);

	return date.toLocaleDateString("en-US", options);
};
export const mediumDateFormatMinusTimezone = (val) => {
	var d = new Date(val);
	var options = {
		hour: "numeric",
		minute: "numeric",
	};
	var offset = d.getTimezoneOffset() * 60000;
	var absoluteDate = new Date(d.getTime() - offset);
	return absoluteDate.toLocaleDateString("en-US", options);
};
export const longDateFormat = (val) => {
	var options = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
	};
	var date = new Date(val);

	return date.toLocaleDateString("en-US", options);
};
export const getScrollbarWidth = () => {
	// Create the measurement node
	var scrollDiv = document.createElement("div");
	scrollDiv.className = "scrollbar-measure";
	document.body.appendChild(scrollDiv);

	// Get the scrollbar width
	var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

	// Delete the DIV
	document.body.removeChild(scrollDiv);
	return scrollbarWidth;
};
export const documentLockTextMap = (lockedByDisplay) => ({
	0: [
		{
			text: `Currently Locked by ${lockedByDisplay || "???"}`,
			disabled: true,
		},
	],
	1: [
		{
			text: `Currently in use by ${lockedByDisplay || "???"}`,
			disabled: true,
		},
	],
	2: [
		{
			text: `Currently checked out to ${lockedByDisplay || "???"}`,
			disabled: true,
		},
	],
	3: [
		{
			text: `Currently checked out to & in use by ${
				lockedByDisplay || "???"
			}`,
			disabled: true,
		},
	],
	OUT_OF_BOUNDS: [
		{
			text: `Currently locked by ${lockedByDisplay || "???"}`,
			disabled: true,
		},
	],
});
export const getDocumentLockText = (status, displayName) => {
	return status >= 0 && status < 4
		? documentLockTextMap(displayName || "")[status][0].text
		: "Document is not locked";
};
export function getCheckoutMenuItems(
	lockedByDisplay,
	lockedBySub,
	lockStatus,
	documentID,
	basepath,
	userSub,
	fn,
	matterID,
	userCanOverrideDocumentLocks
) {
	const onSelect = (status) => () =>
		fn(documentID, status, basepath, matterID);
	/*

		Only lock statuses 0,1,2,3 should be set by the server.  Also,
		if a lockStatus is 1,2,3, this means the document is in a
		locked state and ought to have a lockedBySub set as well.  But
		mysql v5.7 does not support such a database-level constraint on
		multiple columns, so it is possible that a document could be in a
		"weird" state, and thus it should be handled as gracefully as
		possible by the browser



		This is what the menu options should show for a document, depending
		on its current lockStatus:

		0 - Check out (lock)
		
		1 - Check out
			Remove "in-use" lock
		
		2 - Check in (unlock)
		
		3 - Check in
			Remove "in-use"
			Check in & remove "in-use" (unlock file)



		This is the static text that should be displayed describing
		the documents current lock status:

		0 - 
		1 - Currently in use by Steve
		2 - Currently checked out to Steve
		3 - Currently checked out and in use by Steve


	*/
	const statusMap = {
		noLock: {
			0: [{ text: "Check-Out (Lock)", onSelect: onSelect(2) }],
			OUT_OF_BOUNDS: [
				{
					text: "Unlock",
					onSelect: onSelect(0),
				},
			],
		},
		lockedByMe: {
			0: [{ text: "Check-In (Unlock)", onSelect: onSelect(0) }],
			1: [
				{ text: "Check-Out (Lock)", onSelect: onSelect(3) },
				{ text: "Remove in-use (Unlock)", onSelect: onSelect(0) },
			],
			2: [{ text: "Check-In (Unlock)", onSelect: onSelect(0) }],
			3: [
				{ text: "Check-In", onSelect: onSelect(1) },
				{ text: "Remove in-use", onSelect: onSelect(2) },
				{
					text: "Check-In and remove in-use (Unlock)",
					onSelect: onSelect(0),
				},
			],
			OUT_OF_BOUNDS: [
				{
					text: "Unlock",
					onSelect: onSelect(0),
				},
			],
		},
		lockedByOther: documentLockTextMap(lockedByDisplay),
		adminLockedByOther: {
			0: [{ text: "Check in (unlock)", onSelect: onSelect(0) }],
			1: [{ text: "Remove in-use (unlock)", onSelect: onSelect(0) }],
			2: [{ text: "Check in (unlock)", onSelect: onSelect(0) }],
			3: [
				{ text: "Check in", onSelect: onSelect(1) },
				{ text: "Remove in-use", onSelect: onSelect(2) },
				{
					text: "Check in and remove in-use (unlock)",
					onSelect: onSelect(0),
				},
			],
			OUT_OF_BOUNDS: [{ text: "Unlock", onSelect: onSelect(0) }],
		},
	};

	const getLockedByMeMenu = (status) => {
		if (status >= 0 && status < 4) {
			return statusMap.lockedByMe[lockStatus];
		} else {
			//out of bounds lockStatus
			return statusMap.lockedByMe.OUT_OF_BOUNDS;
		}
	};
	const getLockedByOtherMenu = (status) => {
		if (status >= 0 && status < 4) {
			return statusMap.lockedByOther[lockStatus];
		} else {
			//out of bounds lockStatus
			return statusMap.lockedByOther.OUT_OF_BOUNDS;
		}
	};
	const getAdminLockedByOtherMenu = (status) => {
		const lockedByOtherMenu = getLockedByOtherMenu(status);
		if (status >= 0 && status < 4) {
			return lockedByOtherMenu.concat(
				statusMap.adminLockedByOther[lockStatus]
			);
		} else {
			//out of bounds lockStatus
			return lockedByOtherMenu.concat(
				statusMap.adminLockedByOther.OUT_OF_BOUNDS
			);
		}
	};
	const getNoLockedBySubMenu = (status) => {
		if (status === 0) {
			return statusMap.noLock[0];
		} else {
			return statusMap.noLock.OUT_OF_BOUNDS;
			//invalid state
		}
	};
	if (lockedBySub) {
		if (lockedBySub === userSub) {
			return getLockedByMeMenu(lockStatus);
		} else {
			if (userCanOverrideDocumentLocks) {
				return getAdminLockedByOtherMenu(lockStatus);
			} else {
				return getLockedByOtherMenu(lockStatus);
			}
		}
	} else {
		return getNoLockedBySubMenu(lockStatus);
	}
}
export function capitalize(str) {
	return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
}
export const filmCreditsFlexHelper = (leftText, rightText) => {
	/*	
	film credits are aligned left for the character name, and right for the actor eg:

	Leia Organa.............Carrie Fisher
	Han Solo................Harrison Ford
*/
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
			}}
		>
			<span>{leftText}</span>
			<span style={{ textAlign: "right" }}>{rightText}</span>
		</div>
	);
};
export const splitFilename = (filename) => {
	const spl = filename.split(".");
	const extension = spl.length > 1 ? spl.pop() : "";
	const basename = spl.join(".");
	const extensionLowerCase = extension.toLowerCase();
	return { basename, extension, extensionLowerCase };
};

//having a '%' sign in the URL does not work properly for a number of reasons
//having to do with react-router-dom history library.  a side-effect of
//this workaround is that a user cannot name a folder PCT__SIGN
export const encodeFullpath = (fullpath) => {
	const encoded = fullpath
		.replace(/%/g, "PERCENT__SIGN")
		.replace(/#/g, "HASHTAG__SYMBOL");

	return encoded;
};
export const decodeFullpath = (fullpath) => {
	return fullpath
		? fullpath
				.replace(/PERCENT__SIGN/g, "%")
				.replace(/HASHTAG__SYMBOL/g, "#")
		: fullpath;
};
export const mapFirmDocsMatterID = (matterID, basepath) => {
	// both public and private firm docs have matterID=0.  For the search index,
	// we need to be able to query against just public or just private, so use -1
	// as the fake matterID for public firm docs
	return parseInt(matterID, 10) === 0
		? basepath.match(/^\/public/) || basepath.match(/^public/)
			? -1
			: 0
		: matterID;
};
export const getFirmDocsDisplayName = (matterID) => {
	if (matterID === 0) {
		return "Private Firm Docs";
	} else if (matterID === -1) {
		return "Public Firm Docs";
	} else {
		return "";
	}
};
export const getMatterLink = (matterID) => {
	if (matterID === 0) {
		return "/firmDocs/private/";
	} else if (matterID === -1) {
		return "/firmDocs/public/";
	} else {
		return `/matters/${matterID}/docs/`;
	}
};
export const pluralize = (noun, num) => noun + (num !== 1 ? "s" : "");
export const pluralizeWithNum = (noun, num) => num + " " + pluralize(noun, num);
export const trimFileName = (fn) => {
	if (typeof fn !== "string") {
		return "";
	}
	//Trim whitespace from filename and extension
	return fn.lastIndexOf(".") !== -1
		? fn.substring(0, fn.lastIndexOf(".")).trim() +
				"." +
				fn.substring(fn.lastIndexOf(".") + 1).trim()
		: fn.trim();
};
export function numberWithCommas(x) {
	if (x === 0) {
		return "0";
	} else {
		return x && x.toString
			? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
			: "";
	}
}
export function convertToPDFAllowed(filename) {
	const { extensionLowerCase } = splitFilename(filename);
	return ["doc", "docx"].includes(extensionLowerCase);
}
export function ocrOnDemandAllowed(filename) {
	const { extensionLowerCase } = splitFilename(filename);
	return ["pdf"].includes(extensionLowerCase);
}

//.tif files can only be displayed inline by Safari as of Feb 2024
export function canShowInlineImagePreview(filename) {
	const { extensionLowerCase } = splitFilename(filename);
	return ["jpg", "jpeg", "png", "bmp", "gif", "webp", "svg"].includes(
		extensionLowerCase
	);
}

export function msToTime(ms) {
	let seconds = (ms / 1000).toFixed(1);
	let minutes = (ms / (1000 * 60)).toFixed(1);
	let hours = (ms / (1000 * 60 * 60)).toFixed(1);
	let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
	if (seconds < 60) return seconds + " Sec";
	else if (minutes < 60) return minutes + " Min";
	else if (hours < 24) return hours + " Hrs";
	else return days + " Days";
}

// common functions for permissions
export const makeDictOfDisplayNamesBySub = (allUsers) => {
	var out = {};
	allUsers.forEach((u) => {
		out[u.sub] = u.displayName;
	});
	return out;
};
export const makeDictOfGroupNamesByID = (allGroups) => {
	var out = {};
	allGroups.forEach((g) => {
		out[g.id] = { name: g.name, type: g.type };
	});
	return out;
};

export const permissionFeatureGlobalSettingOptions = [
	{ value: "allUsers", label: "All Users" },
	{ value: "specifiedUsers", label: "Only Specified Users" },
];

export const permissionFeatureGlobalSettingLookup = {
	allUsers: 0,
	specifiedUsers: 1,
};
export const matterPermissionGlobalSettingOptions = [
	{ value: "allUsers", label: "All Users" },
	{ value: "specifiedUsers", label: "Only Specified Users" },
];
export const matterPermissionGlobalSettingLookup = {
	allUsers: 0,
	specifiedUsers: 1,
};
export const allowListDropdownMap = (user, subLookup) => ({
	value: user.userSub,
	label: subLookup[user.userSub],
});

export const groupListDropdownMap = (group, groupLookup) => ({
	value: group.groupID,
	label: groupLookup[group.groupID].name, //group.name,
	isFixed: groupLookup[group.groupID].type === "FIRM_ADMIN",
});

export const findAdminGroups = (availableGroups) => {
	return availableGroups.filter((g) => g.isFixed);
};

export function makeDebouncedFunction(func, waitTimeMS) {
	let timeout;
	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, waitTimeMS);
	};
}
