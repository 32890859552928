import React from "react";
import { FormGroup, FormControl, Form } from "react-bootstrap";

export class LabeledTextArea extends React.Component {
	onFieldChanged(event) {
		const value = event.target.value;
		this.props.onChange && this.props.onChange(this.props.name, value);
	}
	render() {
		return (
			<div
				className={
					"LabeledInput LabeledTextArea " +
					(this.props.className || "")
				}
			>
				<div className="InputLabel">{this.props.label}</div>
				<textarea
					{...this.props}
					style={{
						cursor: this.props.disabled ? "not-allowed" : "default",
					}}
					onChange={this.onFieldChanged.bind(this)}
					defaultValue={this.props.defaultValue || ""}
				/>
			</div>
		);
	}
}
export class TextArea extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
		};
		this.onKeyPress = this.onKeyPress.bind(this);
	}
	onFieldChanged(event) {
		this.props.onChange && this.props.onChange(event.target.value);
	}
	onKeyPress(event) {
		if (event.key === "Enter") {
			this.props.onEnterKey && this.props.onEnterKey(event);
			event.preventDefault();
			return false;
		}
	}
	render() {
		return (
			<Form>
				<FormGroup>
					<FormControl
						type={this.props.type || "text"}
						autoComplete={this.props.autocomplete || ""}
						defaultValue={this.props.defaultValue || ""}
						placeholder={this.props.placeholder}
						onChange={this.onFieldChanged.bind(this)}
						onKeyPress={this.onKeyPress}
					/>
				</FormGroup>
			</Form>
		);
	}
}
