import {
	GET_CLIO_INTEGRATION_SETTINGS_SUCCESS,
	GET_CLIO_INTEGRATION_SETTINGS_FAILURE,
	SET_CLIO_INTEGRATION_SETTINGS_SUCCESS,
	SET_CLIO_INTEGRATION_SETTINGS_FAILURE,
	GET_TENANT_FEATURES_USAGE_AND_FEATURES_SUCCESS,
} from "../actions/types";

//
const initialState = {
	ewsUrl: null,
	loading: true,
	tenantFeaturesLoading: true,
	tenantFeaturesRequestCounts: {
		clioIntegrationRequestCount: 0,
	},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case "GET_EWS_URL_SUCCESS":
			var ewsUrl = action.payload;
			return {
				...state,
				loading: false,
				ewsUrl,
			};
		case "SET_EWS_URL_SUCCESS":
			return {
				...state,
				loading: true,
			};
		case GET_CLIO_INTEGRATION_SETTINGS_SUCCESS:
			return {
				...state,
				loading: false,
				clioIntegrationSettingStatus: action.payload,
			};
		case GET_CLIO_INTEGRATION_SETTINGS_FAILURE:
			return {
				...state,
				loading: false,
				clioIntegrationSettingStatus: 0,
			};
		case SET_CLIO_INTEGRATION_SETTINGS_SUCCESS:
			return {
				...state,
				loading: false,
				clioIntegrationSettingStatus: action.payload,
				tenantFeaturesRequestCounts: {
					...state.tenantFeaturesRequestCounts,
					clioIntegrationRequestCount:
						state.tenantFeaturesRequestCounts
							.clioIntegrationRequestCount + 1,
				},
			};
		case SET_CLIO_INTEGRATION_SETTINGS_FAILURE:
			return {
				...state,
				loading: false,
				clioIntegrationSettingStatus: action.payload,
				tenantFeaturesRequestCounts: {
					...state.tenantFeaturesRequestCounts,
					clioIntegrationRequestCount:
						state.tenantFeaturesRequestCounts
							.clioIntegrationRequestCount + 1,
				},
			};
		case GET_TENANT_FEATURES_USAGE_AND_FEATURES_SUCCESS:
			return {
				...state,
				tenantFeaturesLoading: false,
				...action.payload,
			};
		default:
			return state;
	}
}
