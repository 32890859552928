import {
	CLOSE_GLOBAL_SNACKBAR,
	OPEN_GLOBAL_SNACKBAR,
	MULTIPLE_FILE_UPLOAD_SUCCESS,
	MULTIPLE_FILE_UPLOAD_FAILURE,
	BULK_FILE_UPLOAD_FAILURE,
	DELETE_SUCCESS,
	RESTORE_SUCCESS,
	PASTE_SUCCESS,
	PASTE_FAILURE,
	UPLOAD_NEW_VERSION_SUCCESS,
	SET_DOCUMENT_LOCK_SUCCESS,
	CLOSE_MATTER_SUCCESS,
	REOPEN_MATTER_SUCCESS,
	REINDEX_SUCCESS,
	REINDEX_FAILURE,
	BULK_DOWNLOAD_FAILURE,
	BULK_DOWNLOAD_SUCCESS,
	FOLDER_NAME_CONFLICT,
	BULK_EXTERNAL_SEND_FAILURE,
	BULK_EXTERNAL_SEND_SUCCESS,
	UPDATE_PERMISSION_FEATURE_DETAILS_REQUEST,
	UPDATE_PERMISSION_FEATURE_DETAILS_SUCCESS,
	UPDATE_PERMISSION_FEATURE_DETAILS_ERROR,
	CONVERT_TO_PDF_SUCCESS,
	CONVERT_TO_PDF_FAILURE,
	UPLOAD_NEW_VERSION_FAILURE,
	REQUEST_OCR_SUCCESS,
	REQUEST_OCR_FAILURE,
	UPDATE_OCR_SNACKBAR_MESSAGE,
	AUDIT_SEARCH_SUCCESS,
	AUDIT_SEARCH_FAILURE,
	INDEX_MISSING_SEARCH_ITEMS_SUCCESS,
	INDEX_MISSING_SEARCH_ITEMS_FAILURE,
	OPEN_PERSISTENT_ERROR_SNACKBAR,
	OPEN_PERSISTENT_SNACKBAR,
} from "../actions/types";

import { shortDateFormat } from "../utilities/utilities";

const initialState = {
	open: false,
	message: "",
	variant: "",
	autoHideDuration: 6000,
	loadingSpinnerVisible: false,
	allowClose: true,
	disableWindowBlurListener: false,
};

const allowedVariants = ["error", "warning", "info", "success"];
export default function (state = initialState, action) {
	switch (action.type) {
		case MULTIPLE_FILE_UPLOAD_SUCCESS:
			return {
				...state,
				open: true,
				message: `Successfully uploaded ${action.payload.num} ${
					action.payload.objectType || "File"
				}${action.payload.num > 1 ? "s" : ""}`,
				variant: "success",
			};
		case MULTIPLE_FILE_UPLOAD_FAILURE:
			return {
				...state,
				open: true,
				message: `There was a problem with the upload.  The ${
					action.payload ? action.payload.num : ""
				} file(s) were not successfully uploaded`,
				variant: "error",
			};
		case BULK_FILE_UPLOAD_FAILURE:
			return {
				...state,
				open: true,
				message:
					action.payload && action.payload.message
						? action.payload.message
						: `There was a problem with the upload.  The ${
								action.payload ? action.payload.num : ""
							} file(s) were not successfully uploaded`,
				variant: "error",
				autoHideDuration:
					action.payload &&
					action.payload.autoHideDuration !== undefined
						? action.payload.autoHideDuration
						: initialState.autoHideDuration,
			};
		case CONVERT_TO_PDF_SUCCESS:
			return {
				...state,
				open: true,
				message: action.payload.message,
				variant: "success",
				loadingSpinnerVisible: false,
				allowClose: true,
				autoHideDuration:
					action.payload &&
					action.payload.autoHideDuration !== undefined
						? action.payload.autoHideDuration
						: initialState.autoHideDuration,
			};
		case REQUEST_OCR_SUCCESS:
			return {
				...state,
				open: true,
				message: action.payload.message,
				variant: "success",
				loadingSpinnerVisible: false,
				allowClose: true,
				autoHideDuration:
					action.payload &&
					action.payload.autoHideDuration !== undefined
						? action.payload.autoHideDuration
						: initialState.autoHideDuration,
			};
		case REQUEST_OCR_FAILURE:
			return {
				...state,
				variant: "error",
				message: action.payload.message,
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case DELETE_SUCCESS:
			return {
				...state,
				open: true,
				message: `${action.payload.itemType} deleted`,
				variant: "success",
			};
		case RESTORE_SUCCESS:
			return {
				...state,
				open: true,
				message: `${action.payload.itemType} restored`,
				variant: "success",
			};
		case PASTE_SUCCESS:
			return {
				...state,
				open: true,
				message: `${action.payload.itemType} ${action.payload.verb}`,
				variant: "success",
				autoHideDuration: 6000,
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case PASTE_FAILURE:
			return {
				...state,
				open: true,
				message:
					action.payload.message ||
					"There was a problem with pasting",
				variant: "error",
				autoHideDuration: 6000,
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case REINDEX_SUCCESS:
			return {
				...state,
				open: true,
				message:
					"Reindex job created.  Click refresh button to see progress",
				variant: "success",
				autoHideDuration: 6000,
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case REINDEX_FAILURE:
			return {
				...state,
				open: true,
				message: "Reindex job failed",
				variant: "error",
				autoHideDuration: 6000,
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case UPLOAD_NEW_VERSION_SUCCESS:
			return {
				...state,
				open: true,
				message: "New Version Uploaded",
				variant: "success",
			};
		case UPLOAD_NEW_VERSION_FAILURE:
			return {
				...state,
				open: true,
				message: `${
					action && action.payload && action.payload.message
						? action.payload.message
						: "New version could not be uploaded"
				}`,
				variant: "error",
			};
		case SET_DOCUMENT_LOCK_SUCCESS:
			return {
				...state,
				open: true,
				message: `Document Checked ${
					action.payload.lockStatus ? "Out" : "In"
				}`,
				variant: "success",
			};
		case CLOSE_MATTER_SUCCESS:
			return {
				...state,
				open: true,
				message: `Matter closed on ${shortDateFormat(
					action.payload.closedDate
				)}`,
				variant: "success",
			};
		case REOPEN_MATTER_SUCCESS:
			return {
				...state,
				open: true,
				message: "Matter Reopened",
				variant: "success",
			};
		case CLOSE_GLOBAL_SNACKBAR:
			return {
				...state,
				open: false,
				autoHideDuration: 6000,
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case OPEN_GLOBAL_SNACKBAR:
			return {
				...state,
				open: true,
				message: action.payload.message,
				variant: allowedVariants.includes(action.payload.variant)
					? action.payload.variant
					: "info",
				autoHideDuration: action.payload.autoHideDuration || 6000,
				loadingSpinnerVisible:
					action.payload.loadingSpinnerVisible || false,
				allowClose: false,
				disableWindowBlurListener:
					action.payload.disableWindowBlurListener || false,
			};
		case "OPEN_LOADING_SNACKBAR":
			return {
				...state,
				open: true,
				autoHideDuration: null,
				variant: "info",
				message: action.payload.message || "Calculating...",
				loadingSpinnerVisible: true,
				allowClose: false,
			};
		case "UPDATE_SNACKBAR_MESSAGE":
			return {
				...state,
				message: action.payload.message,
			};
		case UPDATE_OCR_SNACKBAR_MESSAGE:
			return {
				...state,
				variant: allowedVariants.includes(action.payload.variant)
					? action.payload.variant
					: "info",
				loadingSpinnerVisible: false,
				allowClose: true,
				message: action.payload.message,
			};
		case BULK_DOWNLOAD_FAILURE:
			return {
				...state,
				variant: "error",
				message: action.payload.message,
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case BULK_DOWNLOAD_SUCCESS:
			return {
				...state,
				variant: "success",
				message: action.payload.message,
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case BULK_EXTERNAL_SEND_FAILURE:
			return {
				...state,
				variant: "error",
				message: action.payload.message,
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case BULK_EXTERNAL_SEND_SUCCESS:
			return {
				...state,
				variant: "success",
				message: action.payload.message,
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case FOLDER_NAME_CONFLICT:
			return {
				...state,
				open: true,
				variant: "error",
				message:
					"There is already a " +
					(action.payload.length && action.payload[0].isDeleted
						? "deleted "
						: "") +
					"folder with that name",
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case UPDATE_PERMISSION_FEATURE_DETAILS_REQUEST:
			return {
				...state,
				open: true,
				variant: "info",
				message: "Updating Permissions...",
				loadingSpinnerVisible: true,
				allowClose: false,
			};
		case UPDATE_PERMISSION_FEATURE_DETAILS_SUCCESS:
			return {
				...state,
				open: true,
				variant: "success",
				message: "Permissions Successfully Updated",
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case UPDATE_PERMISSION_FEATURE_DETAILS_ERROR:
			return {
				...state,
				open: true,
				variant: "error",
				message: "Unable to Update Permissions",
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case CONVERT_TO_PDF_FAILURE:
			return {
				...state,
				variant: "error",
				message: action.payload.message,
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case AUDIT_SEARCH_SUCCESS:
			return {
				...state,
				open: true,
				message: action.payload.message,
				variant: "success",
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case AUDIT_SEARCH_FAILURE:
			return {
				...state,
				variant: "error",
				message: action.payload.message,
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case INDEX_MISSING_SEARCH_ITEMS_SUCCESS:
			return {
				...state,
				open: true,
				message: action.payload.message,
				variant: "success",
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case INDEX_MISSING_SEARCH_ITEMS_FAILURE:
			return {
				...state,
				variant: "error",
				message: action.payload.message,
				loadingSpinnerVisible: false,
				allowClose: true,
			};
		case OPEN_PERSISTENT_ERROR_SNACKBAR:
			return {
				...initialState,
				open: true,
				variant: "error",
				allowClose: true,
				autoHideDuration: null,
				message: action.payload.message || "An unknown error occured",
			};
		case OPEN_PERSISTENT_SNACKBAR:
			return {
				...initialState,
				open: true,
				variant: action.payload.variant,
				allowClose: true,
				autoHideDuration: null,
				message: action.payload.message,
				loadingSpinnerVisible: false,
			};

		default:
			return state;
	}
}
