/*
	Handles New and Edit matter functionality.  props.editMode determines which.
*/

import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
	createNewMatter,
	updateMatter,
	unmountNewEditMatter,
} from "../actions/matterActions";

import {
	getEverythingForEditTenant,
	getEverythingForNewTenant,
	editTenant,
	createTenant,
	unmountNewEditTenant,
} from "../actions/adminActions";
import TwoColumnWrapper from "./TwoColumnWrapper";
import { setSubheaderText } from "../actions/navigateActions";
import { LabeledTextInput } from "./TextInput";

import { LabeledDropDownSelect } from "./DropDownSelect";

import { humanFileSize, numberWithCommas } from "../utilities/utilities";

import lxwConstants from "../config/lexworkplace-constants";
const lexWorkplaceEditions = lxwConstants.lexWorkplaceEditions;

const initialState = {
	name: "",
	tenantID: null,
	customerID: "",
	usernamePrefix: "",
};

const makeDataUsageMenuOption = (gigabytes) => {
	const limitInBytes = gigabytes * 1000 * 1000 * 1000;
	return { label: humanFileSize(limitInBytes, true), value: gigabytes };
};
const makeOCRPerMonthBaselineMenuOption = (ocrOperationsPerMonth) => {
	return {
		label: numberWithCommas(ocrOperationsPerMonth),
		value: ocrOperationsPerMonth,
	};
};

const makeOCREnabledMenuOption = (enabled) => {
	return {
		label: enabled ? "Enabled" : "Disabled",
		value: enabled,
	};
};

const storageLimitOptions = (() => {
	var out = [];
	for (let gigabytes = 500; gigabytes <= 20000; gigabytes += 100) {
		out.push(makeDataUsageMenuOption(gigabytes));
	}
	return out;
})();

const ocrPerMonthOptions = (() => {
	var out = [];

	for (let val = 2500; val <= 50000; val += 2500) {
		out.push(makeOCRPerMonthBaselineMenuOption(val));
	}
	return out;
})();

const lxwEditionOptions = (() => {
	return Object.keys(lexWorkplaceEditions.options).map((editionCode) => {
		return {
			label: lexWorkplaceEditions.options[editionCode].display,
			value: editionCode,
		};
	});
})();

const ocrEnabledOptions = [
	makeOCREnabledMenuOption(true),
	makeOCREnabledMenuOption(false),
];

const makeEditionMenuOption = (editionCode) => {
	return lexWorkplaceEditions.options[editionCode]
		? {
				label: lexWorkplaceEditions.options[editionCode].display,
				value: editionCode,
			}
		: { label: "Unknown", value: "unknown" };
};

class NewTenant extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			customerID: "",
			usernamePrefix: "",
			tenantID: null,
			loading: true,
		};
		this.handleChange = this.handleChange.bind(this);
	}
	static getDerivedStateFromProps(props, state) {
		if (state.loading && !props.loading) {
			const newState = props.editMode
				? {
						loading: false,
						name: props.firmDetails.name,
						customerID: props.firmDetails.customerID,
						usernamePrefix: props.firmDetails.usernamePrefix,
						tenantID: props.firmDetails.id,
						storageLimitGB: makeDataUsageMenuOption(
							props.usageLimits.STORAGE_LIMIT_GB
						),
						lxwEdition: makeEditionMenuOption(
							props.firmDetails.lxwEdition
						),
						ocrEnabled: makeOCREnabledMenuOption(
							props.tenantFeatures.automaticOCR
						),
						ocrPerMonthBaseline: makeOCRPerMonthBaselineMenuOption(
							props.usageLimits.OCR_PER_MONTH_BASELINE
						),
					}
				: {
						...initialState,
						tenantID: props.firmDetails
							? props.firmDetails.id
							: 420,
						loading: false,
						storageLimitGB: makeDataUsageMenuOption(
							props.newFirmDefaultStorageLimitGB
						),
						lxwEdition: makeEditionMenuOption(
							props.newFirmEditionCode
						),
						ocrEnabled: makeOCREnabledMenuOption(
							props.newFirmDefaultOCREnabled
						),
						ocrPerMonthBaseline: makeOCRPerMonthBaselineMenuOption(
							props.newFirmDefaultOCRPerMonthBaseline
						),
					};
			return newState;
		} else {
			return null;
		}
	}
	componentDidMount() {
		if (this.props.editMode) {
			this.props.getEverythingForEditTenant(
				this.props.match.params.tenantID
			);
		} else {
			this.props.getEverythingForNewTenant();
			this.props.setSubheaderText("New Firm");
		}
	}
	componentWillUnmount() {
		this.props.unmountNewEditTenant();
	}
	handleChange(key, value) {
		this.setState({ [key]: value });
	}
	submit() {
		if (this.props.editMode) {
			this.props.editTenant(
				this.props.match.params.tenantID,
				this.state.name,
				this.state.storageLimitGB.value,
				this.state.lxwEdition.value,
				this.state.ocrEnabled.value,
				this.state.ocrPerMonthBaseline.value
			);
		} else {
			this.props.createTenant(
				this.state.tenantID,
				this.state.name,
				this.state.customerID,
				this.state.usernamePrefix,
				this.state.storageLimitGB.value,
				this.state.lxwEdition.value,
				this.state.ocrEnabled.value,
				this.state.ocrPerMonthBaseline.value
			);
		}
	}
	render() {
		if (this.props.navigateAwayFromCreatePage) {
			return (
				<Redirect
					to={
						this.props.editMode
							? "/admin"
							: `/admin/tenant/${this.state.tenantID}/users`
					}
				/>
			);
		}
		if (this.state.loading) {
			return <div />;
		} else {
			return (
				<TwoColumnWrapper
					headingText="Firm Information"
					iconName="briefcase"
					canSubmit={
						this.state.name !== "" &&
						this.state.customerID !== "" &&
						this.state.tenantID !== "" &&
						this.state.usernamePrefix !== ""
					}
					submit={this.submit.bind(this)}
					submitButtonText={
						this.props.editMode ? "Update Firm" : "Create Firm"
					}
					leftChildren={
						<div>
							<LabeledTextInput
								label="Firm Name"
								name="name"
								placeholder="New Firm Name"
								defaultValue={
									this.props.editMode
										? this.props.firmDetails.name
										: null
								}
								onChange={this.handleChange}
							/>
							<LabeledTextInput
								label="Internal Customer ID"
								name="customerID"
								placeholder="Customer ID"
								disabled={this.props.editMode}
								defaultValue={
									this.props.editMode
										? this.props.firmDetails.customerID
										: null
								}
								onChange={this.handleChange}
							/>

							<LabeledDropDownSelect
								label="Storage Limit"
								name="storageLimitGB"
								placeholder="Storage Limit"
								isAsync
								limitNumber={100}
								options={storageLimitOptions}
								defaultValue={
									this.props.editMode &&
									this.props.usageLimits
										? [
												makeDataUsageMenuOption(
													this.props.usageLimits
														.STORAGE_LIMIT_GB
												),
											]
										: [
												makeDataUsageMenuOption(
													this.props
														.newFirmDefaultStorageLimitGB
												),
											]
								}
								handler={this.handleChange}
							/>
							<LabeledDropDownSelect
								label="OCR Operations Per Month Baseline"
								name="ocrPerMonthBaseline"
								placeholder="OCR Operations per Month"
								isAsync
								limitNumber={100}
								options={ocrPerMonthOptions}
								defaultValue={
									this.props.editMode &&
									this.props.usageLimits
										? [
												makeOCRPerMonthBaselineMenuOption(
													this.props.usageLimits
														.OCR_PER_MONTH_BASELINE
												),
											]
										: [
												makeOCRPerMonthBaselineMenuOption(
													this.props
														.newFirmDefaultOCRPerMonthBaseline
												),
											]
								}
								handler={this.handleChange}
							/>
						</div>
					}
					rightChildren={
						<div>
							<div>
								{this.props.failedCreateAttemptDuplicateID && (
									<div style={{ color: "red" }}>
										ERROR! this tenantID is already in use
										by a different firm
									</div>
								)}
								<LabeledTextInput
									label="Tenant ID (auto populated with next available)"
									name="tenantID"
									placeholder="New Tenant ID"
									disabled={this.props.editMode}
									defaultValue={this.props.firmDetails.id}
									onChange={this.handleChange}
								/>
							</div>
							<LabeledTextInput
								label="Username Prefix (use firm email domain)"
								name="usernamePrefix"
								placeholder="firm email domain"
								disabled={this.props.editMode}
								defaultValue={
									this.props.editMode
										? this.props.firmDetails.usernamePrefix
										: null
								}
								onChange={this.handleChange}
							/>
							<LabeledDropDownSelect
								label="Edition"
								name="lxwEdition"
								placeholder="Edition"
								isAsync
								options={lxwEditionOptions}
								defaultValue={[
									makeEditionMenuOption(
										this.props.editMode
											? this.props.firmDetails.lxwEdition
											: this.props.newFirmEditionCode
									),

									/*this.props.editMode ? makeDataUsageMenuOption(
													this.props.usageLimits
														.STORAGE_LIMIT_GB
												) : { label: "Core", value: "CORE" },*/
								]}
								handler={this.handleChange}
							/>
							<LabeledDropDownSelect
								label="Automatic OCR"
								name="ocrEnabled"
								placeholder="OCR"
								isAsync
								options={ocrEnabledOptions}
								defaultValue={[
									makeOCREnabledMenuOption(
										this.props.editMode
											? this.props.tenantFeatures
													.automaticOCR
											: this.props
													.newFirmDefaultOCREnabled
									),
								]}
								handler={this.handleChange}
							/>
						</div>
					}
				/>
			);
		}
	}
}
const mapStateToProps = (state, ownProps) => {
	var newFirmEditionCode = ownProps.match.params.newFirmEditionCode;
	var newFirmDefaultStorageLimitGB,
		newFirmDefaultOCREnabled,
		newFirmDefaultOCRPerMonthBaseline;
	if (newFirmEditionCode) {
		const { defaultValues } =
			lexWorkplaceEditions.options[newFirmEditionCode];
		newFirmDefaultStorageLimitGB = defaultValues.limits.STORAGE_LIMIT_GB;
		newFirmDefaultOCRPerMonthBaseline =
			defaultValues.limits.OCR_PER_MONTH_BASELINE;
		newFirmDefaultOCREnabled = defaultValues.features.automaticOCR;
	}

	return {
		availableMatterTags: state.matter.availableMatterTags,
		availableMatterTypes: state.matter.availableMatterTypes,
		availableAssignedStaff: state.matter.availableAssignedStaff,
		availableOfficeBranches: state.matter.availableOfficeBranches,
		availableClients: state.matter.availableClients,
		matterDetails: state.matter.matterDetails,
		clientDetails: state.client.clientDetails,
		firmDetails: state.admin.firmDetails,
		usageLimits: state.admin.usageLimits,
		tenantFeatures: state.admin.tenantFeatures,
		loading: state.admin.newEditTenantLoading,
		failedCreateAttemptDuplicateID:
			state.admin.failedCreateAttemptDuplicateID,
		navigateAwayFromCreatePage: state.admin.navigateAwayFromCreatePage,
		newFirmEditionCode,
		newFirmDefaultStorageLimitGB,
		newFirmDefaultOCREnabled,
		newFirmDefaultOCRPerMonthBaseline,
	};
};

export default connect(mapStateToProps, {
	createNewMatter,
	updateMatter,
	getEverythingForNewTenant,
	getEverythingForEditTenant,
	unmountNewEditMatter,
	setSubheaderText,
	editTenant,
	createTenant,
	unmountNewEditTenant,
})(NewTenant);
