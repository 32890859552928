import React, { Component } from "react";
import { connect } from "react-redux";
import { setSubheaderText } from "../actions/navigateActions";
import {
	getClioIntegrationSettingStatus,
	setClioIntegrationSettingStatus,
} from "../actions/tenantActions";
import { CircularProgress, Divider, Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SwitchWithStyles = withStyles(() => ({
	root: {
		width: 28,
		height: 16,
		padding: 0,
		display: "flex",
	},
	switchBase: {
		padding: 2,
		color: "grey",
		"&$checked": {
			transform: "translateX(12px)",
			color: "white",
			"& + $track": {
				opacity: 1,
				backgroundColor: "var(--lw-blue)",
				borderColor: "var(--lw-blue)",
			},
		},
	},
	thumb: {
		width: 12,
		height: 12,
		boxShadow: "none",
	},
	track: {
		border: `1px solid grey`,
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: "white",
	},
	checked: {},
}))(Switch);

const SwitchOnGridWithLabel = ({
	switchValue,
	handleSwitchValueChange,
	label,
	disabled,
	isValueUpdating,
	valueUpdatedSuccess,
	valueUpdatedFailed,
}) => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<h4>{label}</h4>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: 5,
				}}
			>
				<div>
					{isValueUpdating ? <CircularProgress size={15} /> : ""}
					{valueUpdatedSuccess ? (
						<FontAwesomeIcon color="green" icon="check" size="1x" />
					) : (
						""
					)}
					{valueUpdatedFailed ? (
						<FontAwesomeIcon color="red" icon="times" size="1x" />
					) : (
						""
					)}
				</div>
				<SwitchWithStyles
					checked={switchValue}
					onChange={handleSwitchValueChange}
					name={label}
					disabled={disabled || isValueUpdating}
				/>
			</div>
		</div>
	);
};
class IntegrationSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			canSubmit: false,
			clioIntegrationSettingStatus: false,
			clioIntegrationSettingUpdating: false,
			clioIntegrationSettingUpdateSuccess: false,
			clioIntegrationSettingUpdateFailed: false,
			loading: true,
		};
		this.handleSwitchChange = this.handleSwitchChange.bind(this);
		this.handleStateUpdate = this.handleStateUpdate.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		if (state.loading && !props.loading) {
			const newState = {
				loading: false,
				clioIntegrationSettingStatus:
					!!props.clioIntegrationSettingStatus,
			};
			return newState;
		} else {
			return null;
		}
	}

	componentDidMount() {
		this._isMounted = true;
		this.props.setSubheaderText("Manage Integrations");
		this.props.getClioIntegrationSettingStatus();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleSwitchChange(key, value) {
		this.setState({ [key]: value });
	}

	handleStateUpdate(baseKey, value, success, failed) {
		const settingToUpdate = `${baseKey}Status`;
		const updatingKey = `${baseKey}Updating`;
		const successKey = `${baseKey}UpdateSuccess`;
		const failedKey = `${baseKey}UpdateFailed`;
		setTimeout(() => {
			if (this._isMounted) {
				this.setState(
					{
						[settingToUpdate]: value,
						[updatingKey]: false,
						[successKey]: success,
						[failedKey]: failed,
					},
					() => {
						if (this._isMounted) {
							setTimeout(() => {
								this.setState({
									[successKey]: false,
									[failedKey]: false,
								});
							}, 1000);
						}
					}
				);
			}
		}, 1000);
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.clioIntegrationRequestCount !==
			this.props.clioIntegrationRequestCount
		) {
			const updateSuccessfull =
				!!this.props.clioIntegrationSettingStatus ===
				this.state.clioIntegrationSettingStatus;

			const updateFailed =
				!!this.props.clioIntegrationSettingStatus !==
				this.state.clioIntegrationSettingStatus;

			this.handleStateUpdate(
				"clioIntegrationSetting",
				!!this.props.clioIntegrationSettingStatus,
				updateSuccessfull,
				updateFailed
			);
		}
	}

	render() {
		return (
			<div style={{ width: 600, paddingTop: 20, paddingLeft: 15 }}>
				{this.state.loading ? null : (
					<div>
						{this.props.canEditClioIntegrationSetting ? (
							<></>
						) : (
							<span>
								Only Firm Administrators can edit these settings
							</span>
						)}
						<SwitchOnGridWithLabel
							label={"Clio Manage"}
							switchValue={
								this.state.clioIntegrationSettingStatus
							}
							isValueUpdating={
								this.state.clioIntegrationSettingUpdating
							}
							valueUpdatedSuccess={
								this.state.clioIntegrationSettingUpdateSuccess
							}
							valueUpdatedFailed={
								this.state.clioIntegrationSettingUpdateFailed
							}
							handleSwitchValueChange={() => {
								this.handleSwitchChange(
									"clioIntegrationSettingStatus",
									!this.state.clioIntegrationSettingStatus
								);
								let newSwitchValue =
									!this.state.clioIntegrationSettingStatus;
								this.setState(
									{
										clioIntegrationSettingStatus:
											newSwitchValue,
										clioIntegrationSettingUpdating: true,
									},
									() => {
										this.props.setClioIntegrationSettingStatus(
											newSwitchValue
										);
									}
								);
							}}
							labelPlacement="start"
							disabled={!this.props.canEditClioIntegrationSetting}
						/>
						<Divider />
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	clioIntegrationSettingStatus: state.tenant.clioIntegrationSettingStatus,
	loading: state.tenant.loading,
	canEditClioIntegrationSetting: state.user.canEditClioIntegrationSetting,
	clioIntegrationRequestCount:
		state.tenant.tenantFeaturesRequestCounts.clioIntegrationRequestCount,
});
export default connect(mapStateToProps, {
	getClioIntegrationSettingStatus,
	setClioIntegrationSettingStatus,
	setSubheaderText,
})(IntegrationSettings);
