/*
	Handles New and Edit matter functionality.  props.editMode determines which.
*/

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	createNewMatter,
	updateMatter,
	getEverythingForNewMatter,
	getEverythingForEditMatter,
	unmountNewEditMatter,
} from "../actions/matterActions";

import {
	getPermissionGroups,
	createPermissionGroup,
	editPermissionGroup,
} from "../actions/permissionsActions";

import TwoColumnWrapper from "./TwoColumnWrapper";
import { setSubheaderText } from "../actions/navigateActions";
import { LabeledTextInput } from "./TextInput";
import { LabeledDropDownSelect } from "./DropDownSelect";

const initialState = {
	name: "",
	groupMembers: [],
};
const valueLabelMap = (x) => ({ value: x.sub, label: x.displayName });

const tabMap = [
	{ label: "Matter Information", url: "" },
	{ label: "Matter Permissions", url: "/permissions" },
];

const mapUsersKeepMeFixed = (arr, sub, isFirmAdminGroup) => {
	//the isFixed property hides the x button next to a dropdown list item
	return arr.map((x) =>
		isFirmAdminGroup && x.sub === sub
			? { ...valueLabelMap(x), isFixed: true }
			: valueLabelMap(x)
	);
};

class NewMatter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			name: "",
			groupMembers: [],
		};
		this.handleChange = this.handleChange.bind(this);
		this.switchedTab = this.switchedTab.bind(this);
		this.makeDictOfDisplayNamesBySub =
			this.makeDictOfDisplayNamesBySub.bind(this);
		this.makeDictOfGroupNamesByID =
			this.makeDictOfGroupNamesByID.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		if (state.loading && !props.loading) {
			const newState = props.editMode
				? {
						loading: false,
						name: props.focusedPermissionGroup.name,
						groupMembers: props.focusedPermissionGroup.members
							? mapUsersKeepMeFixed(
									props.focusedPermissionGroup.members,
									props.cognitoSub,
									props.isFirmAdminGroup
								)
							: [],
					}
				: {
						...initialState,
						loading: false,
					};
			return newState;
		} else {
			return null;
		}
	}

	componentDidMount() {
		this.props.getPermissionGroups();
		if (this.props.createNew) {
			console.log("new group");
		} else if (this.props.edit) {
			console.log("edit group", this.props.match.params.groupID);
		}
	}
	componentWillUnmount() {
		this.props.unmountNewEditMatter();
	}
	handleChange(key, value) {
		this.setState({ [key]: value });
	}
	submit() {
		const groupData = {
			name: this.state.name,
			groupMembers:
				this.state.groupMembers && this.state.groupMembers.length
					? this.state.groupMembers.map((m) => m.value)
					: [],
		};
		if (this.props.editMode) {
			this.props.editPermissionGroup(
				parseInt(this.props.match.params.groupID, 10),
				groupData
			);
		} else {
			this.props.createPermissionGroup(groupData);
		}
	}
	switchedTab(value) {
		var newURL;

		const endOfURL = tabMap[value].url;
		if (this.props.editMode) {
			newURL = `/matters/${this.props.match.params.id}/edit${endOfURL}`;
		} else {
			newURL = `/matters/new${endOfURL}`;
		}

		this.props.history.push(newURL);
	}
	makeDictOfDisplayNamesBySub() {
		var out = {};
		this.props.availableAssignedStaff.forEach((u) => {
			out[u.sub] = u.displayName;
		});
		return out;
	}
	makeDictOfGroupNamesByID() {
		var out = {};
		this.props.permissionsGroups.forEach((g) => {
			out[g.id] = { name: g.name, type: g.type };
		});
		return out;
	}
	render() {
		if (this.props.loading) {
			return <div />;
		} else {
			return (
				<div>
					{
						<TwoColumnWrapper
							headingText="Permission Group Information"
							iconName="users"
							canSubmit={this.state.name !== ""}
							submit={this.submit.bind(this)}
							submitButtonText={
								this.props.editMode
									? "Update Group"
									: "Create Group"
							}
							leftChildren={
								<div>
									<LabeledTextInput
										label="Group Name*"
										name="name"
										placeholder="New Group Name"
										defaultValue={
											this.props.editMode
												? this.props
														.focusedPermissionGroup
														.name
												: null
										}
										onChange={this.handleChange}
									/>
									<LabeledDropDownSelect
										label="Group Members"
										isMulti
										name="groupMembers"
										isAsync
										isClearable
										value={this.state.groupMembers}
										defaultValue={
											this.props.editMode &&
											this.props.focusedPermissionGroup
												.members
												? mapUsersKeepMeFixed(
														this.props
															.focusedPermissionGroup
															.members,
														this.props.cognitoSub,
														this.props
															.isFirmAdminGroup
													)
												: []
										}
										options={mapUsersKeepMeFixed(
											this.props.users,
											this.props.cognitoSub,
											this.props.isFirmAdminGroup
										)}
										handler={this.handleChange}
									/>
								</div>
							}
							rightChildren={<div />}
						/>
					}
				</div>
			);
		}
	}
}
const mapStateToProps = (state, ownProps) => {
	const focusedPermissionGroup = ownProps.editMode
		? state.permissions.permissionGroups.filter(
				(g) => g.id === parseInt(ownProps.match.params.groupID, 10)
			)
		: [];
	return {
		availableMatterTags: state.matter.availableMatterTags,
		availableMatterTypes: state.matter.availableMatterTypes,
		availableAssignedStaff: state.matter.availableAssignedStaff,
		availableOfficeBranches: state.matter.availableOfficeBranches,
		availableClients: state.matter.availableClients,
		matterDetails: state.matter.matterDetails,
		clientDetails: state.client.clientDetails,
		permissionsGroups: state.matter.permissionsGroups,
		matterPermissions: state.matter.matterPermissions,
		loading: state.permissions.newEditPermissionsLoading,
		hasPermissionsAccess: state.user.hasPermissionsAccess,
		availablePermissionGroups: state.permissions.permissionGroups,
		focusedPermissionGroup: ownProps.editMode
			? focusedPermissionGroup[0]
			: [],
		users: state.permissions.users,
		usersBySub: state.permissions.usersBySub,
		cognitoSub: state.user.cognitoUser.attributes.sub,
		isFirmAdminGroup: focusedPermissionGroup[0]
			? focusedPermissionGroup[0].type === "FIRM_ADMIN"
			: false,
	};
};
NewMatter.propTypes = {
	createNewMatter: PropTypes.func.isRequired,
	updateMatter: PropTypes.func.isRequired,
	getEverythingForNewMatter: PropTypes.func.isRequired,
	getEverythingForEditMatter: PropTypes.func.isRequired,
	unmountNewEditMatter: PropTypes.func.isRequired,
	setSubheaderText: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
	createNewMatter,
	updateMatter,
	getEverythingForNewMatter,
	getEverythingForEditMatter,
	unmountNewEditMatter,
	setSubheaderText,
	getPermissionGroups,
	createPermissionGroup,
	editPermissionGroup,
})(NewMatter);
