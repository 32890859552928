import { CLOSE_GLOBAL_SNACKBAR, OPEN_GLOBAL_SNACKBAR } from "./types";

export function openSnackbar(mixin) {
	return function (dispatch) {
		dispatch({
			type: OPEN_GLOBAL_SNACKBAR,
			payload: mixin,
		});
	};
}

export function closeSnackbar() {
	return function (dispatch) {
		dispatch({
			type: CLOSE_GLOBAL_SNACKBAR,
		});
	};
}
